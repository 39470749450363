import {faqsList} from "./dashboardFaqList";
import {CustomAccordion, CustomAccordionSummary, CustomAccordionDetails} from "../../../components/CustomAccordion"
import {useState} from "react";

const DashboardFaqContainer = () => {
    const [expanded, setExpanded] = useState(false);
    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };
    return (
        <div id="faq" className="max-w-[95%] px-2 py-10 sm:px-2 lg:px-2 lg:py-14 mx-auto">
            <div class="flex items-center space-x-2 pb-4">
                <div
                    class="flex h-7 w-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-full" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2"
                              d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path>
                    </svg>
                </div>
                <h4 class="text-lg font-medium text-slate-700 dark:text-navy-100">
                    Getting Started
                </h4>
            </div>
            {faqsList.map((faq, index) => (
                <CustomAccordion expanded={expanded === index}
                                 onChange={handleChange(index)} key={index}>
                    <CustomAccordionSummary
                        className={`${expanded === index ? "" : ""}`}
                        expandIcon={<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M7 10L12 15L17 10" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </g>
                        </svg>}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <h3 className="!font-semibold">{faq.question}</h3>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <div>
                            {faq.type === 'list' ? (
                                <div>
                                    <span>{faq.first}</span>
                                    <ul>
                                        {faq.answer.split('\n').map((item, itemIndex) => (
                                            item ? <li className="list-disc ml-4"
                                                       key={itemIndex}>{item.replace(/^\s*\d+\.\s*/, '')}</li> : null
                                        ))}
                                    </ul>
                                    <span>{faq.last}</span>
                                </div>
                        ) : (
                        <span>{faq.answer}</span>
                            )}
                        </div>
                    </CustomAccordionDetails>
                </CustomAccordion>
            ))}
        </div>
    )
}
export default DashboardFaqContainer;