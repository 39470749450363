import { getStartedList } from "./getStartedList";
import { CardContent, CardMedia, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
const GetStartedContainer = () => {
    const StyledPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        borderRadius: '12px',
        backgroundColor: '#f0f4f8', 
        transition: '0.3s ease-in-out',
        '&:hover': {
          boxShadow: theme.shadows[5],
        },
          height: '100%',
        display: 'flex', 
        flexDirection: 'column', 
      }));
      
    return (
        <Grid item xs={12}  className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-slate-300 text-gray-800 mb-8">
                Get Started with JOURNiCRAFT
            </h2>
            <Grid container spacing={2}>
                {getStartedList.map((item, idx) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={idx}>
                        <StyledPaper
                        className="!mb-5 dark:bg-navy-600"
                        >
                        <Grid className="w-full flex justify-center mt-4">
                            <CardMedia
                            component="img"
                            alt={item.title}
                            sx={{ width: "40px", height: "40px", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}
                            image={item.image}
                            />
                        </Grid>

                        <CardContent className="dark:text-slate-300" sx={{ backgroundColor: item.color, "&.MuiCardContent-root:last-child": {
                            paddingBottom:0} }}>
                            <h6 className="!mb-3 !font-semibold !text-xl">
                            {item.title}
                            </h6>
                            <p className="text-gray-600 dark:text-slate-400">
                            {item.description}
                            </p>
                        </CardContent>
                        </StyledPaper>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    
    );
}

export default GetStartedContainer;