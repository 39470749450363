import React, {useEffect, useState} from "react";
import * as yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';

import {useLayoutContext} from "../../layout/layoutProvider";
import {useLocation, useOutletContext} from "react-router-dom";
import {openCloseSidebarPanel} from "../../utils/helpers";
import FetchClient from "../../utils/fetchClient";
import {createRoot} from "react-dom/client";
import WordCloud from "react-d3-cloud";
import {useAuth} from "../../utils/authProvider";
import Dialog from '@mui/material/Dialog';

import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

const logoColor = "#21cbaf";

const CoursePage = (props) => {
    const [files, setFiles] = useState([])

    const location = useLocation()
    const {setStore} = useAuth();
    const [uploadLoading, setUploadLoading] = useState(false);
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const [courseDashbaordData, setCourseDashbaordData] = useState({
        total_chats: 0, total_exercises: 0, total_users: 0, total_messages: 0, subscribed_users: 0
    });
    const user = useOutletContext().user;
    const course = useOutletContext().course;
    const [editCourseInputs, setEditCourseInputs] =
        useState({
            name: course.name,
            teacher_id: course.teacher_id,
            course_description: course.course_description,
            teacher_description: course.teacher_description,
            gpt_secret_key: course.gpt_secret_key,
            assistant_selection: course.assistant_selection,
            id: course.id,
            code: course.code,
            token_usage_per_student: course.token_usage_per_student
        });
    const [editCourseModal, setEditCourseModal] = useState(false);
    const [addCourseMaterialModal, setAddCourseMaterialModal] = useState(false);
    const [courseMaterial, setCourseMaterial] = useState({label: null, content: null});
    const [course_edit_response_error, setEditCourseResponseError] = useState({
        message: "",
        customized_error_code: -1
    });
    const [errorsList, setErrorsList] = useState([]);
    const [courseMaterials, setCourseMaterials] = useState([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);

    function updateMenu() {
        if (location.pathname.includes('course')) {
            setSidebarSelectedMenuItem('course');
        }
    }


    const getCourseDashboardData = () => {
        FetchClient({
            method: 'get',
            url: 'course/' + course.id + '/dashboard_data'
        }).then(response => {
            setCourseDashbaordData(response.data);
        })
    }

    function getUserKeywords() {
        FetchClient({
            method: 'get',
            url: 'course/' + course.id + '/keywords',
            params: {
                course_id: course.id
            }
        }).then(response => {
            let keywords = [];
            for (let i = 0; i < response.data.length; i++) {
                keywords.push({text: response.data[i].title, value: response.data[i].proportion})
            }
            if (keywords.length > 0) {
                document.getElementById('no-course-keyword').textContent = "";
                createRoot(document.getElementById('course-wordcloud-canvas')).render(<WordCloud width={1000}
                                                                                                 height={400}
                                                                                                 data={keywords}
                                                                                                 font="Candara"
                                                                                                 fontWeight="bold"
                                                                                                 rotate={(word) => 0}/>);
            } else {
                document.getElementById('no-course-keyword').textContent = "No Keyword Extracted!";
                createRoot(document.getElementById('course-wordcloud-canvas')).render(<WordCloud width={1000}
                                                                                                 height={100}
                                                                                                 data={keywords}
                                                                                                 font="Candara"
                                                                                                 fontWeight="bold"
                                                                                                 rotate={(word) => 0}/>);
            }
        })
    }

    function openEditCourseModal(event) {
        event.preventDefault();
        setEditCourseResponseError({message: "", customized_error_code: -1});
        setEditCourseModal(true);
    }

    function handleAddCourseMaterialModal() {
        setAddCourseMaterialModal(true);
    }

    const handleEditCourseChange = (event) => {
        const {name, value} = event.target;
        setEditCourseInputs(values => ({...values, [name]: value}));
    }

    function submitEditCourse(event) {
        editCourseInputs.teacher_id = user.id;
        FetchClient({
            method: 'put',
            url: 'course/update',
            data: editCourseInputs
        }).then(response => {
            window.$notification({
                text: 'Course is edited successfully!',
                variant: 'primary',
                position: 'center-top'
            })
            setLoader(false);
            setEditCourseModal(false);
            course.name = editCourseInputs.name;
            course.course_description = editCourseInputs.course_description;
            course.teacher_description = editCourseInputs.teacher_description;
            course.gpt_secret_key = editCourseInputs.gpt_secret_key;
            course.assistant_selection = editCourseInputs.assistant_selection;
            setStore("course", course);
            // window.location.reload();
        }).catch((error) => {
            if (error.response) {
                setEditCourseResponseError(error.response.data.detail);
            }
        });
    }

    useEffect(() => {
        setLoader(false);
        updateMenu();
        openCloseSidebarPanel(false);
        getCourseMaterial();
    }, [location]);

    useEffect(() => {
        if (course && course.role === 'teacher') {
            getCourseDashboardData();
            getUserKeywords();
        }
    }, [course]);

    const keyupHandler = (event) => {
        if (event.key === 'Enter') {
            submitEditCourse();
        }
        return true;
    };
    const handleCourseMaterialChange = (e) => {
        setCourseMaterial((values => ({...values, content: e.target.files[0]})));
    }
    const getCourseMaterial = () => {
        FetchClient({
            method: 'get',
            url: 'course/content',
            params: {
                course_id: course.id
            }
        }).then(response => {
            setCourseMaterials(response?.data)
        }).catch((error) => {
            if (error.response) {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                })
            }
        });
    }
    const addCourseMaterial = async () => {
        const schema = yup.object().shape({
            courseMaterial: yup.object().shape({
                label: yup.string().required("label of material is required !"),
                content: yup.string().required("file is required !")
            }),
        });

        try {
            await schema.validate({courseMaterial}, {abortEarly: false});
            setUploadLoading(true);
            const formData = new FormData();
            formData.append('file', courseMaterial.content);

            FetchClient({
                method: 'post',
                url: 'course/content/add',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    course_id: course.id,
                    label: courseMaterial.label
                },
                data: formData
            }).then(response => {
                window.$notification({
                    text: 'Course material is added successfully!',
                    variant: 'primary',
                    position: 'center-top'
                })
                setUploadLoading(false);
                setErrorsList([]);
                setCourseMaterial({label: null, content: null});
                getCourseMaterial();
                setAddCourseMaterialModal(false);
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
                setUploadLoading(false);
                setAddCourseMaterialModal(false);
                setErrorsList([]);
                setCourseMaterial({label: null, content: null});
                getCourseMaterial();
                setAddCourseMaterialModal(false);
            });
        } catch (error) {
            setErrorsList(error.inner);
        }
    };
    const handleDeleteMaterialModal = (item) => {
        setConfirmDeleteModal(true);
        setSelectedMaterial(item);
    }
    const handleDeleteMaterial = () => {
        setConfirmDeleteModal(false);
        setLoader(true);
        FetchClient({
            method: 'post',
            url: 'course/content/remove',
            params: {
                course_id: course.id,
                course_content_id: selectedMaterial?.id
            },
        }).then(response => {
            window.$notification({
                text: 'Material deleted successfully!',
                variant: 'error',
                position: 'center-top'
            })
            setLoader(false);
            setConfirmDeleteModal(false);
            getCourseMaterial();
        }).catch((error) => {
            if (error.response) {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                })
            }
        });
    }
    const tableCourseMaterials = courseMaterials?.length > 0 && courseMaterials.map((material, index) => (
        <tr key={"question_" + material.id}
            className="border-y border-transparent cursor-pointer border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">{material.label}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{material.name}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 flex">
                <button
                    onClick={() => handleDeleteMaterialModal(material)}
                    className="flex darkmode-toggle btn h-8 w-8 rounded-full p-1 m-1 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    id={"delete" + material.id}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" baseProfile="basic">
                        <g id="Icon-Set-Filled" transform="translate(-469.000000, -1041.000000)" fill="#c74343">
                            <path
                                d="M487.148,1053.48 L492.813,1047.82 C494.376,1046.26 494.376,1043.72 492.813,1042.16 C491.248,1040.59 488.712,1040.59 487.148,1042.16 L481.484,1047.82 L475.82,1042.16 C474.257,1040.59 471.721,1040.59 470.156,1042.16 C468.593,1043.72 468.593,1046.26 470.156,1047.82 L475.82,1053.48 L470.156,1059.15 C468.593,1060.71 468.593,1063.25 470.156,1064.81 C471.721,1066.38 474.257,1066.38 475.82,1064.81 L481.484,1059.15 L487.148,1064.81 C488.712,1066.38 491.248,1066.38 492.813,1064.81 C494.376,1063.25 494.376,1060.71 492.813,1059.15 L487.148,1053.48">
                            </path>
                        </g>
                    </svg>
                </button>
            </td>
        </tr>)
    );
    return (
        <main className="main-content h-100vh mt-0">
            <div className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] mt-20 transition-all duration-[.25s]">
                <div className={(course && course.role === 'teacher' ? ' ' : ' hidden')}>
                    <button
                        class="btn space-x-2 border font-medium text-white bg-warning/90 dark:border-navy-500 dark:text-navy-50 hover:bg-warning/70"
                        onClick={(event) => openEditCourseModal(event)}>
                        <svg width="22px" height="22px"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="none"
                             viewBox="0 0 24 24"
                        >
                            <path d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6" stroke="#ffffff"
                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Edit</span>
                    </button>
                    <button
                        class="btn ml-2 space-x-2 border font-medium text-white bg-primary/90 dark:border-navy-500 dark:text-navy-50 hover:bg-primary/70"
                        onClick={(event) => handleAddCourseMaterialModal(event)}>
                        <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <circle cx="12" cy="12" r="10" stroke="#ffffff" strokeWidth="1.5"></circle>
                                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff"
                                      strokeWidth="1.5" strokeLinecap="round"></path>
                            </g>
                        </svg>
                        <span>Upload material</span>
                    </button>
                </div>
            </div>
            <div
                className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] py-2 mt-2 transition-all duration-[.25s]">
                <div className={(course && course.role === 'teacher' ? ' ' : ' hidden')}>
                    <hr className="my-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50"/>
                    <div className="m-4">
                        <h1 className="text-xl font-bold">
                            {course.name} (code: {course.code}) Data:
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-4 lg:gap-6 text-center">
                        <div className="card justify-center p-4.5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                                        {courseDashbaordData.subscribed_users + " active, out of " + courseDashbaordData.total_users}
                                    </p>
                                    <p className="text-xs+ line-clamp-1">Course Users</p>
                                </div>
                                <div
                                    className="mask is-star flex h-10 w-10 shrink-0 items-center justify-center bg-success">
                                    <i className="fa-solid fa-user text-xl text-white"></i>
                                </div>
                            </div>
                        </div>
                        <div className="card justify-center p-4.5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                                        {courseDashbaordData.total_chats}
                                    </p>
                                    <p className="text-xs+ line-clamp-1">Course chats</p>
                                </div>
                                <div
                                    className="mask is-star flex h-10 w-10 shrink-0 items-center justify-center bg-info">
                                    <i className="fa-solid fa-comments text-xl text-white"></i>
                                </div>
                            </div>
                        </div>
                        <div className="card justify-center p-4.5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                                        {courseDashbaordData.total_messages}
                                    </p>
                                    <p className="text-xs+ line-clamp-1">Course Messages</p>
                                </div>
                                <div
                                    className="mask is-star flex h-10 w-10 shrink-0 items-center justify-center bg-secondary">
                                    <i className="fa-solid fa-comment text-xl text-white"></i>
                                </div>
                            </div>
                        </div>
                        <div className="card justify-center p-4.5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                                        {courseDashbaordData.total_exercises}
                                    </p>
                                    <p className="text-xs+ line-clamp-1">Course Exercises</p>
                                </div>
                                <div
                                    class="mask is-star flex h-10 w-10 shrink-0 items-center justify-center bg-warning">
                                    <i className="fa-solid fa-newspaper text-xl text-white"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                <tr>
                                    <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        #
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Label
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Name
                                    </th>
                                    <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Action
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {courseMaterials.length > 0 && tableCourseMaterials}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 text-center mt-4">
                        <div className="card col-span-12 p-4.5">
                            <div id="no-course-keyword"></div>
                            <div id="course-wordcloud-canvas" className="w-full"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={addCourseMaterialModal}
                onClose={() => setAddCourseMaterialModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Upload material </h1>
                    <button
                        onClick={() => setAddCourseMaterialModal(false)}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:text-white dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <form className="scrollbar-sm dark:text-navy-100 overflow-y-auto px-4 py-4 sm:px-5">
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>label:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Content label"
                                    name="label"
                                    value={courseMaterial?.label}
                                    onChange={(e) => setCourseMaterial((values => ({
                                        ...values,
                                        label: e.target.value
                                    })))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "courseMaterial.label")?.[0]?.errors?.[0]}</span>
                            </label>
                            <div class="filepond fp-bordered">
                                {/*<FilePond*/}
                                {/*    files={files}*/}
                                {/*    onupdatefiles={setFiles}*/}
                                {/*    allowMultiple={false}*/}
                                {/*    maxFiles={1}*/}
                                {/*    server="/api"*/}
                                {/*    name="files"*/}
                                {/*    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'*/}
                                {/*/>*/}
                                <label
                                    className="btn bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                >
                                    <input
                                        tabIndex="-1"
                                        type="file" accept=".pdf,.doc,.docx" onChange={handleCourseMaterialChange}
                                        className="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                                    />
                                    <div class="flex items-center space-x-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                                            />
                                        </svg>
                                        <span>Choose File</span>
                                    </div>
                                </label>
                                <div className="p-2">{courseMaterial?.content?.name}</div>
                            </div>
                        </div>
                        <div
                            className={"flex mt-8 text-center items-center text-primary justify-center" + (uploadLoading ? "" : " hidden")}>
                            <span className="px-2">Uploading! Please wait ... </span>
                            <ClipLoader
                                color={"blue"}
                                loading={uploadLoading}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </form>
                </div>
                <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                    <button
                        className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => {
                            setAddCourseMaterialModal(false);
                            setUploadLoading(false);
                        }}>
                        Cancel
                    </button>
                    <button onClick={addCourseMaterial}
                            className={"btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                                + (uploadLoading ? " disabled" : "")} disabled={uploadLoading ? true : false}>
                        Add
                    </button>
                </div>
            </Dialog>
            <Dialog
                open={editCourseModal}
                onClose={() => setEditCourseModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex w-full justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Edit Course</h1>
                    <button
                        onClick={(e) => setEditCourseModal(false)}
                        className="btn dark:text-navy-100 -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>
                <div
                    className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <form className="scrollbar-sm dark:text-navy-100 overflow-y-auto px-4 py-4 sm:px-5"
                          onKeyUp={(e) => keyupHandler(e)}>
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Title:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="e.g. Organizational Behavior"
                                    name="name"
                                    value={editCourseInputs.name}
                                    onChange={handleEditCourseChange}
                                    type={"text"}
                                />
                            </label>
                            <label className="block">
                                <span>Course Description:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="course_description"
                                    value={editCourseInputs.course_description}
                                    onChange={handleEditCourseChange}
                                    type={"text"}
                                />
                            </label>
                            <label className="block">
                                <span>Introduce Teacher:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="teacher_description"
                                    value={editCourseInputs.teacher_description}
                                    onChange={handleEditCourseChange}
                                    type={"text"}
                                />
                            </label>
                            <label className="block">
                                <span>Assistant Selection Method:</span>
                                <div onKeyDown={keyupHandler}>
                                    <select name="assistant_selection" onChange={handleEditCourseChange}
                                            value={editCourseInputs.assistant_selection}
                                            className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                    >
                                        <option value="random">Random - Assistant randomly is assigned to the chat
                                        </option>
                                        <option value="flexible">Flexible - Students select the assistant themselves
                                        </option>
                                    </select>
                                </div>
                            </label>
                            <label className="block hidden">
                                <span>GPT Secret Key:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="e.g. sk-mO0qIODSOVhhan2xBXIUAFJNpyre2Zwo9w595TeDOGc"
                                    name="gpt_secret_key"
                                    value={editCourseInputs.gpt_secret_key}
                                    onChange={handleEditCourseChange}
                                    type={"text"}
                                />
                            </label>
                            {course_edit_response_error.customized_error_code !== -1 ?
                                <span
                                    className="text-center text-tiny+ text-error">{course_edit_response_error.message}</span>
                                : ""
                            }

                        </div>
                    </form>
                </div>
                <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                    <button
                        className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => setEditCourseModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={submitEditCourse}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Update
                    </button>
                </div>
            </Dialog>
            <Dialog
                open={confirmDeleteModal}
                onClose={() => setConfirmDeleteModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <div
                    className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
                >
                    <img
                        className="mx-auto h-16 w-16"
                        src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                        alt="logo"
                    />
                    <div className="mt-4">
                        <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                            Delete
                        </h2>
                        <p className="mt-2 dark:text-white">
                            Are you sure you want to remove this material for this course?
                        </p>
                        <div className="space-x-2 text-center mt-4">
                            <button
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => setConfirmDeleteModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteMaterial}
                                className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </main>
    );
}

export default CoursePage;