import React, { useState } from 'react';
import { Grid, Paper, styled } from '@mui/material';
import ContactUs from '../contactUs/contactModal';
import FetchClient from '../../../../utils/fetchClient';

const PricingCard = ({ title, price, description, features, linkText, openModal }) => {
    const StyledPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.primary,
        borderRadius: '12px',
        backgroundColor: '#f0f4f8', 
        transition: '0.3s ease-in-out',
        '&:hover': {
            boxShadow: theme.shadows[5],
        },
        width: "100%",
        height: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",  
        justifyContent: "space-between" 
    }));

    const FeaturesList = styled('ul')({
        flexGrow: 1, 
        overflowY: 'auto', 
        padding: 0, 
        listStyle: 'none',
        margin: '20px 0',  
    });
    const [openContactModal, setOpenContactModal] = useState(false);
    const handleCloseContactModal = () =>{
        setOpenContactModal(false);
       
    }
    const handlePost = (data) => {
        FetchClient({
            method: 'post',
            url: 'contact/us',
            data: {
                email: data.email,
                content: data.message
            }
        }).then(response => {
            setOpenContactModal(false);
            window.$notification({
                text: 'Your message sent successfully !',
                variant: 'success',
                position: 'center-top'
            })            
        }).catch((error)=>{
            window.$notification({
            text: 'Oops! There was an error',
            variant: 'error',
            position: 'center-top'
        });
        })
    }
    return (
        <StyledPaper className="!mb-5 dark:bg-navy-600">
            <div className='flex justify-self-end flex-col'>
            <h4 className="font-medium text-lg text-gray-800 dark:text-gray-200">{title}</h4>
            <span className={`mt-5 font-bold text-4xl text-jclogo-600 dark:text-jclogo-100`}>{price}</span>
            <p className={`text-sm mt-5 text-gray-500`}>{description}</p>
            </div>
            <FeaturesList className={`space-y-2 text-sm`}>
                {features.map((feature, index) => (
                    <li key={index} className="flex space-x-2 items-center justify-self-end">
                        <svg
                            className="flex-shrink-0 size-4 text-jclogo-200"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span className="text-gray-800 dark:text-gray-400">{feature}</span>
                    </li>
                ))}
            </FeaturesList>
            <div onClick={() => openModal ? setOpenContactModal(true)  : {}} className='w-full flex justify-center'>
                <a
                    href= {openModal ? undefined  : "/register"}
                    className="mt-8 w-1/2 py-3 px-4 whitespace-nowrap inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-jclogo-200 text-white hover:bg-jclogo-300 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    style={{ marginTop: 'auto' }} 
                >
                    {linkText}
                </a>
            </div>
            <ContactUs isOpen={openContactModal} onClose={()=>handleCloseContactModal()} onConfirm={(data) => handlePost(data)}/>

        </StyledPaper>
    );
};

export default PricingCard;