import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useCookies} from "react-cookie";
import Cookies from "js-cookie";

import FetchClient from "./fetchClient";
import Application from "../app";
import {cookie_object2string, set_cookies_course, set_cookies_token, set_cookies_user} from "./cookieHandler";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [cookies, setCookie, removeCookie] = useCookies();
    // Changed to handle cookiesss
    const [stores, setStores_] = useState(
        {
            token: (cookies.token ? cookies.token : ""),
            user: cookies.user ? cookies.user : null,
            course: cookies.course ? cookies.course : null
        });

    const setStore = (name, value) => {
        if (name === "token" && value === "logout") {
            stores["token"] = "";
            stores["course"] = null;
            stores["user"] = null;
            setStores_(stores);
            removeCookies();
        } else {
            stores[name] = value;
            setStores_(stores);
            updateCookies();
        }
    };

    const updateCookies = () => {
        if (stores["token"]) {
            // setCookie('token', stores["token"], {path: '/', sameSite: 'none', secure: true, maxAge: 24 * 60 * 60 * 1000});
            set_cookies_token(stores["token"]);
            FetchClient.defaults.headers.common["Authorization"] = "Bearer " + stores["token"];
        } else if (cookies.token) {
            // console.log(cookies.token);
            FetchClient.defaults.headers.common["Authorization"] = "Bearer " + cookies.token;
        } else {

        }

        if (stores["user"]) {
            // setCookie('user', stores["user"], {path: '/', sameSite: 'none', secure: true, maxAge: 24 * 60 * 60 * 1000});
            set_cookies_user(stores["user"]);
        }

        if (stores["course"]) {
            // setCookie('course', stores["course"], {path: '/', sameSite: 'none', secure: true, maxAge: 24 * 60 * 60 * 1000});
            set_cookies_course(stores["course"]);
        }
    };

    const removeCookies = () => {
        // removeCookie('user', {path: '/'});
        // removeCookie('token', {path: '/'});
        // removeCookie('course', {path: '/'});
        document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'user=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'course=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        FetchClient.defaults.headers.common["Authorization"] = "";
    }

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            stores,
            setStore,
            updateCookies
        }),
        [stores]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
