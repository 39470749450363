import * as React from 'react';
import {useState} from 'react';
import FetchClient from "../../utils/fetchClient";
import * as yup from 'yup';
import {useLayoutContext} from '../../layout/layoutProvider';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../utils/authProvider';
import ClipLoader from "react-spinners/ClipLoader";

const AddModel = (props) => {
    const [model, setModel] = useState("");
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);

    const [embededModelCommand, setEmbededModelCommand] = useState("");
    const [embededModelName, setEmbededModelName] = useState("");
    const [errorsList, setErrorsList] = useState([]);
    const [modelCreated, setModelCreated] = useState(false);
    const store = useAuth();
    const courseId = store?.stores?.course?.id;
    const user = store?.stores?.user;
    const [file, setFile] = useState("");
    const postNewModel = async () => {
        const schema = yup.object().shape({
            embededModelName: yup.string().required("Name is required !"),
        });
        try {
            await schema.validate({embededModelName}, {abortEarly: false});
            setLoading(true);
            FetchClient({
                method: 'post',
                url: 'chat/wizard/embedding/model/create',
                params: {course_id: courseId},
                data: {
                    owner: user?.name,
                    name: embededModelName,
                    chat_type_id: props.wizard.id,
                    course_id: courseId
                }
            }).then(response => {
                setEmbededModelCommand("");
                setEmbededModelName("");
                setModelCreated(true);
                setModel(response?.data);

                setErrorsList([]);
                window.$notification({
                    text: 'Your Model is added !',
                    variant: 'success',
                    position: 'center-top'
                })
                setLoading(false);
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
                setLoading(false);
            })
        } catch (err) {
            setErrorsList(err.inner);
        }

    };
    const handleUploadFile = async () => {
        const schema = yup.object().shape({
            file: yup.string().required("File is required !"),
        });
        try {
            await schema.validate({file}, {abortEarly: false});
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            window.$notification({
                text: 'This may take some time please wait',
                variant: 'error',
                position: 'center-top'
            });
            FetchClient({
                method: 'post',
                url: 'chat/wizard/embedding/model/file/add',
                params: {
                    chat_wizard_embedding_model_id: model?.id,
                    course_id: courseId
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }).then(response => {
                setFile("");
                setErrorsList([]);
                window.$notification({
                    text: 'Your file is added !',
                    variant: 'success',
                    position: 'center-top'
                })
                setLoading(false);
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
                setLoading(false);
            })
        } catch (err) {
            setErrorsList(err.inner);
        }

    };
    return (
        <>
            <div className="flex w-full justify-between rounded-t-lg bg-slate-200 dark:bg-navy-800 sm:px-5">
                <h1 className="text-base font-medium text-slate-700 dark:text-navy-100 p-4">Add model </h1>

            </div>
            <div className="relative flex w-full mt-20 origin-top flex-col overflow-hidde dark:bg-navy-700">
                <form className="overflow-y-auto ml-16 mr-28 sm:px-5">
                    <button
                        onClick={() => props.back(false)}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        <svg width="20px" height="25px" fill="#ffffff" viewBox="0 0 24 24" id="left-arrow"
                             xmlns="http://www.w3.org/2000/svg" class="icon line">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path id="primary" d="M21,12H3M6,9,3,12l3,3"
                                      stroke="#ffffff"></path>
                            </g>
                        </svg>
                        <span>
                            Go back
                        </span>
                    </button>
                    <div className="mt-4  space-y-4">
                        <label className="block">
                            <span>Name:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="name"
                                disabled={model?.name}
                                value={model?.name ? model?.name : embededModelName}
                                onChange={(e) => setEmbededModelName(e.target.value)}
                                type={"text"}
                            />
                            <span
                                className="text-error-focus">{errorsList.filter((name) => name.path === "embededModelName")?.[0]?.errors?.[0]}</span>

                        </label>
                    </div>
                    {modelCreated === true ?
                        <div className="mt-4 ">
                            <label className="block">
                                <span>File:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    type="file" accept=".pdf,.doc,.docx" onChange={(e) => setFile(e.target.files[0])}/>
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "file")?.[0]?.errors?.[0]}</span>
                            </label>
                        </div>
                        : ""}
                </form>
                <div
                    className={"flex mt-8 text-center items-center text-primary justify-center" + (loading ? "" : " hidden")}>
                    <span className="px-2">Please wait ... </span>
                    <ClipLoader
                        color={"blue"}
                        loading={loading}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            </div>
            <div className="!text-right mx-16 px-4 py-4 dark:bg-navy-700">
                {modelCreated === true ? <button
                        onClick={() => handleUploadFile()}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Upload
                    </button> :
                    <button
                        onClick={() => postNewModel()}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Add
                    </button>}
            </div>

        </>
    )
}

export default AddModel;