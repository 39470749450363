import React, {useRef, useState} from 'react';
import moment from 'moment';
import userIcon from '../../../../images/user-black.png';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

const MessageChat = (props) => {
    const message = props.message;
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState("");
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };
    const messagesEndRef = useRef(null)
    return <div className='mb-6 w-full card flex flex-col items-start' id={"chat-feedback-menu-" + message?.id}>
        <div className="p-4 lg:p-6 w-full">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                    <div className="flex">
                        <div x-ref="popperRef" className="avatar h-12 w-12">
                            <img className="mask is-squircle" src={userIcon} alt="avatar"/>
                        </div>
                    </div>
                    <div>
                        <a href="#"
                           className="font-medium text-slate-700 line-clamp-1 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light dark:focus:text-accent-light">
                            <strong>From:</strong> {message?.sender_name}
                        </a>
                        <div className='flex'><strong>To:</strong>
                            {message?.receivers_info.length > 3 ?
                                <svg onMouseLeave={() => setOpen(false)} onClick={(e) => handleClick(e)}
                                     className='mt-1 cursor-pointer' width="20px" height="20px" viewBox="0 0 24 24"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                                            className='fill-navy-200'></path>
                                    </g>
                                </svg>
                                :
                                message?.receivers_info.length > 0 && message?.receivers_info.map((receiver, index) => {
                                    return (
                                        <div className='ml-3'
                                             key={index}> {receiver?.receiver_name} {index != message?.receivers_info.length - 1 ? "," : ""}</div>
                                    )
                                })
                            }</div>
                        <div className="mt-1.5 flex items-center text-xs">
                            <span className="line-clamp-1">{moment(message?.creation_date).format("MMMM DD YYYY")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center space-x-3 sm:hidden">
                <button
                    className="btn space-x-2 rounded-full border border-slate-300 px-4 text-xs+ font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="h-4.5 w-4.5 text-slate-400 dark:text-navy-300" fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                    </svg>

                    <span>Save</span>
                </button>
                <div className="flex">
                    <button
                        className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                        <i className="fab fa-twitter text-base"></i>
                    </button>
                    <button
                        className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                        <i className="fab fa-linkedin text-base"></i>
                    </button>
                    <button
                        className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                        <i className="fab fa-instagram text-base"></i>
                    </button>
                    <button
                        className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                        <i className="fab fa-facebook text-base"></i>
                    </button>
                </div>
            </div>
        </div>

        <div className="mb-4 p-4 lg:p-6 w-full font-inter text-base text-slate-600 dark:text-navy-200">
            <div className='mt-5' dangerouslySetInnerHTML={{__html: message?.body}}></div>
        </div>


        <Popper
            className="!z-50 !w-60 "
            open={open}
            anchorEl={anchorEl}
            placement={"bottom"}
            transition>
            {({TransitionProps}) => (
                <Fade
                    className="px-3 !shadow-none rounded-md border border-slate-150 border-2 bg-slate-100 text-slate-800 dark:bg-navy-600 dark:text-navy-100"
                    {...TransitionProps} timeout={350}>
                    <Paper>
                        <ul>
                            {message?.receivers_info.length > 0 && message?.receivers_info.map((receiver, index) => {
                                return (
                                    <li className='p-2' key={index}> {receiver?.receiver_name} </li>
                                )
                            })}
                        </ul>
                    </Paper>
                </Fade>
            )}
        </Popper>

    </div>
}

export default MessageChat;