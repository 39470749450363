import React, {useEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import Modal from '../../../components/modal'
import Popper from '../../../components/popper'
import Select from 'react-select';

import FetchClient from "../../../utils/fetchClient";
import {useLayoutContext} from "../../../layout/layoutProvider";
import ListMenu from "../../../components/listmenu";
import WordCloud from 'react-d3-cloud';
import {createRoot} from "react-dom/client";
import {openCloseSidebarPanel} from "../../../utils/helpers";
import {useAuth} from "../../../utils/authProvider";
import {Dialog} from "@mui/material";
import {CustomStyles, Option} from "../../../components/selectOptions";
import ReactQuill from "react-quill";
import * as yup from "yup";

const MessageSidebarMenu = (props) => {
    const location = useLocation()
    const navigate = useNavigate();

    const {setLoader, sidebarSelectedMenuItem} = useLayoutContext();
    const {stores, setStore} = useAuth();
    const [folder, setFolder] = useState("inbox");
    const [users, setUsers] = useState([]);
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [messageItem, setMessageItem] = useState({subject: "", body: "", reply_status: false, receivers_info: []})
    const [sendModal, setSendModal] = useState(false);
    const [errorsList, setErrorsList] = useState([]);

    const user = props.user;
    const course = props.course;
    const courseId = course?.id;
    const userCourseRole = course?.role;

    const handleRoute = (folder) => {
        openCloseSidebarPanel(true);
        setTimeout(() => {
            navigate("/messages/" + folder, {state: {folder: folder}});
            setFolder(folder);
        }, 300);
    }

    useEffect(() => {
        getUsers();
        if (location.pathname.includes('sent')) {
            handleRoute("sent");
        } else {
            handleRoute("inbox");
        }
    }, []);

    const getUsers = () => {
        if (userCourseRole === 'teacher') {
            FetchClient({
                method: 'get',
                url: 'course/' + courseId + '/users',
                params: {
                    course_id: courseId,
                }
            }).then(response => {
                setUsers(response?.data?.items);
            })
        } else if (userCourseRole === 'student') {
            FetchClient({
                method: 'get',
                url: 'course/human_teacher_assistants',
                params: {
                    course_id: courseId,
                }
            }).then(response => {
                const filteredUsers = response?.data.filter((item) => item?.student_id !== user?.id);
                setUsers([...filteredUsers, {id: course?.teacher_id, name: "Your teacher"}]);
            })
        }
    }
    const keyUpHandler = (event) => {
        if (event.key === 'Enter') {
            postMessage();
        }
        return true;
    };
    const postMessage = async () => {
        const schema = yup.object().shape({
            messageItem: yup.object().shape({
                body: yup.string().required("Body of message is required !"),
                subject: yup.string().required("Subject of message is required !"),
            }),
            selectedUserList: yup.array().min(1, "Selection of receivers is required !").required("")

        });
        try {
            await schema.validate({messageItem, selectedUserList}, {abortEarly: false});

            FetchClient({
                method: 'post',
                url: 'course/course_email/create',
                data: {
                    course_id: courseId,
                    sender_id: user.id,
                    sender_name: user.name,
                    subject: messageItem.subject,
                    body: messageItem.body,
                    receivers_info: selectedUserList.map(item => {
                        return {receiver_id: item.id, receiver_name: item.name};
                    })
                }
            }).then(response => {
                setMessageItem({subject: "", body: "", reply_status: false, receivers_info: []});
                setSendModal(false);
                setSelectedUserList([]);
                window.$notification({
                    text: 'Your message sent successfully !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
            })
        } catch (err) {
            setErrorsList(err.inner);
        }
    }
    const handleSelectUsers = (selected, index) => {
        if (index?.option?.id === -1) {
            setSelectedUserList(users);
        } else
            setSelectedUserList(selected);
    }

    return <div>
        <div className="sidebar-panel">
            <div id="page-sidebar-panel"
                 className="flex  h-full grow flex-col bg-white pl-[var(--main-sidebar-width)] dark:bg-navy-750">
                <div className="flex h-18 w-full items-center justify-between pl-4 pr-1">
                    <div className="flex items-center">
                        <div className="avatar mr-3 hidden h-9 w-9 lg:flex">
                            <div className="is-initial rounded-full bg-warning/10 text-warning">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                                </svg>
                            </div>
                        </div>
                        <p className="text-lg font-medium tracking-wider text-slate-800 line-clamp-1 dark:text-navy-100">
                            Mail
                        </p>
                    </div>
                    <button className="menu-toggle btn h-7 w-7 rounded-full p-0 text-primary
                    hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80
                    dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden"
                            onClick={() => openCloseSidebarPanel(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button>
                </div>

                <div className="flex h-[calc(100%-4.5rem)] grow flex-col">
                    <div className="is-scrollbar-hidden grow overflow-y-auto">
                        <div className="mt-2 px-4">
                            <button onClick={() => setSendModal(true)}
                                    className="btn w-full space-x-2 rounded-full border border-slate-200 py-2 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-500 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4"></path>
                                </svg>
                                <span> New Message</span>
                            </button>
                        </div>
                        <ul className="mt-4 space-y-1.5 px-2 font-inter text-xs+ font-medium">
                            <li onClick={() => handleRoute("inbox")}>
                                <a className={"group flex justify-between space-x-2 rounded-lg "
                                    + (folder === 'inbox' ? "bg-primary/10 p-2 tracking-wide text-primary outline-none transition-all dark:bg-accent-light/10 dark:text-accent-light" :
                                        "p-2 tracking-wide text-slate-800 outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:text-navy-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600")}
                                   href="#">
                                    <div className="flex items-center space-x-2">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             stroke={(folder === 'inbox' ? "currentColor" : "")}
                                             className={"h-4.5 w-4.5 " + (folder === 'inbox' ? "" : "text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200")}
                                             fill="none" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                                                  stroke={(folder === 'inbox' ? "" : "currentColor")}
                                                  d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"></path>
                                        </svg>
                                        <span>Inbox</span>
                                    </div>
                                    {/*<span className="text-primary dark:text-accent-light">2</span>*/}
                                </a>
                            </li>
                            <li onClick={() => handleRoute("sent")}>
                                <a className={"group flex justify-between space-x-2 rounded-lg "
                                    + (folder === 'sent' ? "bg-primary/10 p-2 tracking-wide text-primary outline-none transition-all dark:bg-accent-light/10 dark:text-accent-light" :
                                        "p-2 tracking-wide text-slate-800 outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:text-navy-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600")}
                                   href="#">
                                    <div className="flex items-center space-x-2">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             stroke={(folder === 'sent' ? "currentColor" : "")}
                                             className={"h-4.5 w-4.5 " + (folder === 'sent' ? "" : "text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200")}
                                             fill="none" viewBox="0 0 24 24">
                                            <path fill={(folder === 'sent' ? "" : "currentColor")}
                                                  d="m8.625 3.37.353-.661-.353.661Zm10.49 5.602-.354.662.353-.662Zm-.005 6.082.352.662-.352-.662ZM8.626 20.632l-.352-.662.352.662Zm-4.461-4.08-.718-.216.718.216Zm-.007-9.107-.718.215.718-.215Zm4.713 5.323a.75.75 0 0 0 0-1.5v1.5Zm-.6-8.736 10.49 5.602.707-1.323-10.49-5.602-.707 1.323Zm10.487 10.36L8.274 19.97l.704 1.324 10.484-5.578-.704-1.324ZM4.883 16.768l1.364-4.534-1.437-.432-1.363 4.534 1.436.432Zm1.364-4.965-1.37-4.574-1.437.43 1.37 4.574 1.437-.43Zm-.719.965h3.343v-1.5H5.528v1.5Zm2.746 7.202c-.95.505-1.94.289-2.643-.353-.706-.646-1.091-1.708-.748-2.85l-1.436-.431c-.519 1.724.064 3.374 1.172 4.388 1.113 1.017 2.783 1.409 4.36.57l-.705-1.324ZM18.76 9.634c1.82.972 1.818 3.79-.003 4.758l.704 1.325c2.882-1.534 2.884-5.869.006-7.406l-.707 1.323ZM8.978 2.709c-1.575-.841-3.246-.453-4.36.564C3.507 4.285 2.922 5.935 3.44 7.66l1.437-.43c-.343-1.143.044-2.204.751-2.85.703-.64 1.694-.855 2.643-.348l.707-1.323Z"></path>
                                        </svg>
                                        <span
                                            className={(folder === 'sent' ? "" : "text-slate-800 dark:text-navy-100")}>Sent</span>
                                    </div>
                                </a>
                            </li>
                            {/*<li>*/}
                            {/*    <a className="group flex justify-between space-x-2 rounded-lg p-2 tracking-wide text-slate-800 outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:text-navy-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"*/}
                            {/*       href="#">*/}
                            {/*        <div className="flex items-center space-x-2">*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg"*/}
                            {/*                 className="h-4.5 w-4.5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"*/}
                            {/*                 fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">*/}
                            {/*                <path stroke-linecap="round" stroke-linejoin="round"*/}
                            {/*                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>*/}
                            {/*            </svg>*/}

                            {/*            <span className="text-slate-800 dark:text-navy-100">Draft</span>*/}
                            {/*        </div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <a className="group flex justify-between space-x-2 rounded-lg p-2 tracking-wide text-error outline-none transition-all hover:bg-error/20 focus:bg-error/20"*/}
                            {/*       href="#">*/}
                            {/*        <div className="flex items-center space-x-2">*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none"*/}
                            {/*                 viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">*/}
                            {/*                <path stroke-linecap="round" stroke-linejoin="round"*/}
                            {/*                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>*/}
                            {/*            </svg>*/}

                            {/*            <span>Trash</span>*/}
                            {/*        </div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>

                    {/*<div className="flex flex-col p-4">*/}
                    {/*    <div className="progress h-2 bg-slate-150 dark:bg-navy-500">*/}
                    {/*        <div className="w-7/12 rounded-full bg-info"></div>*/}
                    {/*    </div>*/}
                    {/*    <div className="mt-2 flex items-center justify-between">*/}
                    {/*        <p>*/}
                    {/*            <span className="font-medium text-slate-600 dark:text-navy-100">35GB</span>*/}
                    {/*            of 1TB*/}
                    {/*        </p>*/}
                    {/*        <a href="#"*/}
                    {/*           className="text-xs+ font-medium text-primary outline-none transition-colors duration-300 hover:text-primary/70 focus:text-primary/70 dark:text-accent-light dark:hover:text-accent-light/70 dark:focus:text-accent-light/70">Upgrade</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>

        <Dialog
            open={sendModal}
            onClose={() => setSendModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Compose your message
                    </h3>
                    <button
                        onClick={() => setSendModal(false)}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm dark:text-navy-100 overflow-y-auto px-4 py-4 sm:px-5"
                      onKeyUp={(event) => keyUpHandler(event)}>
                    <div class="h-fulloverflow-clip rounded-xl flex flex-col">
                            <span
                                className="text-error-focus">{errorsList.filter((name) => name.path === "selectedUserList")?.[0]?.errors?.[0]}</span>
                        <Select
                            styles={CustomStyles}
                            placeholder="Select Contacts"
                            noOptionsMessage={() => 'Student dose not exist !'}
                            isClearable
                            isSearchable
                            loadingMessage={() => 'Loading ...'}
                            closeMenuOnSelect={true}
                            value={selectedUserList}
                            isMulti={true}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            options={[{
                                id: -1, name: "All"
                            }, ...users]}
                            onChange={handleSelectUsers}
                        />
                    </div>

                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Subject:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your Message Title"
                                name="title"
                                value={messageItem.subject}
                                onChange={(e) => setMessageItem(messageItem => ({
                                    ...messageItem,
                                    subject: e.target.value
                                }))}
                                type={"text"}
                            />
                            <span
                                className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.subject")?.[0]?.errors?.[0]}</span>
                        </label>
                        <label className="block">
                            <span>body:</span>
                            <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                        value={messageItem.body}
                                        onChange={(data) => setMessageItem(messageItem => ({
                                            ...messageItem,
                                            body: data.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !data.includes("<img") ? "" : data
                                        }))}
                            />
                            <span
                                className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.body")?.[0]?.errors?.[0]}</span>
                        </label>

                    </div>
                    {/* <label className="flex p-4">
                            <span>Can students reply?</span>
                            <input className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                type="checkbox"
                                onChange={(e) => setMessageItem(messageItem=> ({
                                  ...messageItem,
                                  reply_status:e.target.checked}))}
                            />

                        </label> */}
                </form>
                <div className="space-x-2 p-3 text-right">
                    <button
                        data-close-modal
                        className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => setSendModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        data-close-modal
                        onClick={() => postMessage()}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Send
                    </button>
                </div>
            </div>
        </Dialog>
        <Outlet course={course} user={user}/>
    </div>
}

export default MessageSidebarMenu;