import {helpList} from "./helpList";
import {CardContent, CardMedia, Grid, Paper, Typography, styled} from '@mui/material';

const HelpContainer = () => {
    const StyledPaper = styled(Paper)(({theme}) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        borderRadius: '12px',
        backgroundColor: '#f0f4f8',
        transition: '0.3s ease-in-out',
        '&:hover': {
            // transform: 'translateY(-4px)',
            boxShadow: theme.shadows[5],
        },
    }));
    return (
        <Grid id="features" className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-slate-300 text-gray-800 mb-8">
                How JOURNiCRAFT Can Help
            </h2>
            <Grid className="flex flex-wrap">
                {helpList.map((section, index) => (

                    <StyledPaper
                        sx={{
                            height: 'auto',
                            minHeight: "170px",
                            width: {lg: "49%", md: "100%"},
                            backgroundColor: section.color
                        }}
                        className="!mb-5 mx-1 flex flex-col md:flex-row justify-between dark:bg-navy-600"
                    >
                        <CardContent className="justify-start items-start mb-2 dark:text-slate-300" sx={{
                            backgroundColor: section.color, "&.MuiCardContent-root:last-child": {
                                paddingBottom: 0
                            }
                        }}>
                            <h5 className="flex !mb-3 text-left !font-semibold !text-xl w-full">
                                {section.title}
                            </h5>
                            <div className="grid lg:grid-cols-3 lg:gap-y-16 gap-10 w-full">
                                <img
                                    src={section.image}
                                    alt={section.title}
                                    className="rounded-lg m-1"
                                    style={{
                                        // height: 'calc(100% - 0.5.5rem)',
                                        maxHeight: '256px',
                                    }}
                                />
                                <p className="col-span-2 text-left mt-1 dark:text-slate-400">
                                    {section.content}
                                </p>
                            </div>
                        </CardContent>
                    </StyledPaper>
                ))}
            </Grid>
        </Grid>

    );
}
export default HelpContainer;