const badgeIcon1 = (classNames = "h-20 w-20", viewBox = "0 0 800 800") => {
    return <svg xmlns="http://www.w3.org/2000/svg"
                className={classNames + " mx-auto my-auto"} viewBox={viewBox} xmlSpace="preserve">
        <polygon fill="#FF99AA"
                 points="217.637,204.592 335.455,503.918 386.127,408.375 488.329,443.745 370.51,144.42 "/>
        <polygon fill="#FF8095"
                 points="294.359,204.592 176.54,503.918 125.869,408.375 23.668,443.745 141.486,144.42 "/>
        <path fillOpacity="0.15" fill="#333333" d="M409.401,243.228L370.51,144.42l-114.512,45.074
	L141.486,144.42l-38.892,98.808c27.548,51.025,79.81,86.822,140.641,91.248l12.763-32.424l12.763,32.424
	C329.592,330.049,381.853,294.252,409.401,243.228z"/>
        <circle fill="#FFCA66" cx="255.994" cy="160.736" r="152.654"/>
        <g>
            <path fill="#FFFFFF" d="M377.215,201.755c-0.766,0-1.545-0.11-2.316-0.339c-4.277-1.277-6.709-5.78-5.431-10.057
		c11.318-37.901-1.327-84.848-30.751-114.168c-3.163-3.151-3.17-8.268-0.021-11.43c3.149-3.162,8.266-3.171,11.429-0.02
		c33.983,33.864,47.98,86.205,34.829,130.243C383.91,199.49,380.695,201.755,377.215,201.755z"/>
            <path fill="#FFFFFF" d="M311.037,55.809c-1.191,0-2.402-0.265-3.544-0.823c-15.896-7.773-33.701-11.882-51.496-11.882
		c-4.463,0-8.082-3.619-8.082-8.082s3.618-8.082,8.082-8.082c20.241,0,40.503,4.677,58.596,13.525
		c4.01,1.961,5.671,6.802,3.709,10.811C316.902,54.143,314.027,55.809,311.037,55.809z"/>
        </g>
        <path fill="#FFEECC" d="M255.998,259.512c-54.464,0-98.774-44.31-98.774-98.775s44.31-98.776,98.774-98.776
	s98.774,44.31,98.774,98.775S310.462,259.512,255.998,259.512z"/>
        <path fillOpacity="0.1" fill="#293133" d="M192.784,160.738c0-48.395,34.987-88.77,80.994-97.168
	c-5.77-1.053-11.711-1.607-17.781-1.607c-54.464,0-98.775,44.31-98.775,98.774s44.31,98.775,98.775,98.775
	c6.069,0,12.011-0.554,17.781-1.607C227.771,249.508,192.784,209.133,192.784,160.738z"/>
        <g>
            <path fill="#26150C" d="M362.855,160.737c0-58.921-47.935-106.857-106.857-106.857s-106.857,47.935-106.857,106.857
		s47.935,106.857,106.857,106.857S362.855,219.658,362.855,160.737z M255.998,251.429c-50.008,0-90.692-40.685-90.692-90.692
		s40.685-90.692,90.692-90.692s90.692,40.685,90.692,90.692S306.006,251.429,255.998,251.429z"/>
            <path fill="#26150C" d="M495.849,440.784l-88.701-225.351c6.201-17.081,9.587-35.5,9.587-54.696
		C416.736,72.106,344.629,0,255.998,0C167.368,0,95.261,72.106,95.261,160.737c0,19.196,3.388,37.615,9.587,54.696l-88.7,225.351
		c-1.156,2.936-0.497,6.277,1.688,8.554c2.184,2.278,5.495,3.076,8.476,2.043l95.66-33.106l47.429,89.427
		c1.407,2.653,4.161,4.295,7.139,4.295c0.152,0,0.304-0.004,0.457-0.013c3.151-0.178,5.91-2.174,7.066-5.109L256,324.115
		l71.937,182.761c1.156,2.936,3.915,4.931,7.066,5.109c0.153,0.009,0.305,0.013,0.457,0.013c2.978,0,5.732-1.642,7.139-4.295
		l47.43-89.427l95.66,33.106c2.98,1.03,6.292,0.234,8.476-2.043C496.349,447.061,497.004,443.721,495.849,440.784z M175.453,484.617
		l-42.444-80.028c-1.435-2.706-4.22-4.296-7.141-4.296c-0.879,0-1.772,0.144-2.642,0.445l-85.605,29.627
		c0,0,76.416-194.139,76.418-194.145c-0.322,0.818,2.833,5.121,3.328,5.966c1.193,2.037,2.431,4.046,3.712,6.028
		c2.546,3.938,5.266,7.764,8.148,11.464c5.712,7.333,12.064,14.167,18.959,20.4c13.741,12.418,29.636,22.417,46.78,29.425
		c14.314,5.851,29.508,9.624,44.894,11.164c0.04,0.004,0.079,0.01,0.119,0.014L175.453,484.617z M270.961,304.509
		c-74.615,7.66-144.663-45.109-157.433-119.119c-1.404-8.138-2.102-16.395-2.102-24.652
		C111.425,81.02,176.281,16.164,255.998,16.164c79.718,0,144.574,64.856,144.574,144.573c0,72.766-56.616,135.973-129.026,143.711
		C271.35,304.468,271.155,304.488,270.961,304.509z M388.771,400.737c-3.764-1.302-7.916,0.331-9.784,3.851l-42.444,80.028
		l-64.526-163.936c0.04-0.004,0.079-0.01,0.119-0.014c20.029-2.007,39.655-7.787,57.556-16.996
		c16.51-8.494,31.509-19.871,44.137-33.482c4.709-5.076,9.089-10.457,13.102-16.098c2.023-2.844,3.953-5.753,5.787-8.723
		c0.62-1.004,5.763-8.365,5.243-9.148l76.417,194.145L388.771,400.737z"/>
        </g>
    </svg>
}

const badgeIcon2 = (classNames = "h-20 w-20", viewBox = "0 0 800 800") => {
    return <svg xmlns="http://www.w3.org/2000/svg"
                className={classNames + " mx-auto my-auto"} viewBox={viewBox} xmlSpace="preserve">
        <g id="Shape_34_">
            <g>
                <polygon points="12,39.999 12,63.999 22,56 32,63.999 32,39.999   " fill="}#CCCCCC"/>
            </g>
        </g>
        <g id="Shape_35_">
            <g>
                <polygon points="22,56 26,59.199 26,39.999 18,39.999 18,59.199   "
                         fillRule="evenodd" clipRule="evenodd" fill="#75A64B"/>
            </g>
        </g>
        <g id="Shape_36_">
            <g>
                <circle cx="22" cy="22" r="22" fill="#88C057"/>
            </g>
        </g>
        <g id="Star_1_">
            <g>
                <path
                    d="M33.991,18.312c-0.149-0.452-0.542-0.787-1.022-0.875l-6.554-1.202l-3.221-5.572 c-0.235-0.409-0.682-0.663-1.166-0.663c-0.483,0-0.931,0.254-1.166,0.663l-3.22,5.572l-6.554,1.202    c-0.48,0.088-0.873,0.423-1.023,0.875c-0.15,0.45-0.034,0.945,0.303,1.288l4.553,4.639l-0.826,6.304    c-0.062,0.467,0.145,0.93,0.536,1.207c0.233,0.166,0.511,0.25,0.79,0.25c0.19,0,0.382-0.039,0.561-0.12l6.047-2.707l6.046,2.707    c0.18,0.081,0.371,0.12,0.562,0.12c0.279,0,0.556-0.084,0.789-0.25c0.393-0.277,0.598-0.74,0.537-1.207l-0.826-6.304l4.553-4.639    C34.025,19.257,34.142,18.762,33.991,18.312z"
                    fill="#FFFFFF"/>
            </g>
        </g>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
    </svg>
}

const badge1000Tokens = (classNames = "h-20 w-20", viewBox = "0 0 800 800") => {
    return <svg xmlns="http://www.w3.org/2000/svg"
                className={classNames + " mx-auto my-auto"} viewBox={viewBox} xmlSpace="preserve">
        <path fill="#FFCA66" d="M103.185,15.164c-6.81-9.151-20.514-9.151-27.322,0l-41.248,86.419V475.11
                    c0,15.789,12.8,28.589,28.589,28.589h52.639c15.789,0,28.589-12.8,28.589-28.589V101.584L103.185,15.164z"/>
        <path fill="#FF8095" d="M269.662,15.164c-6.81-9.151-20.514-9.151-27.322,0l-41.247,86.419V475.11
                    c0,15.789,12.8,28.589,28.589,28.589h52.639c15.789,0,28.589-12.8,28.589-28.589V101.584L269.662,15.164z"/>
        <path fill="#4DA3FF" d="M436.139,15.164c-6.81-9.151-20.514-9.151-27.322,0l-41.247,86.419V475.11
                    c0,15.789,12.8,28.589,28.589,28.589h52.639c15.789,0,28.589-12.8,28.589-28.589V101.584L436.139,15.164z"/>
        <g>
            <path fillOpacity="0.15" fill="#293133" d="M63.394,475.11V101.584l40.519-84.894l-0.727-1.525
                    c-6.81-9.151-20.514-9.151-27.322,0l-41.248,86.419V475.11c0,15.789,12.8,28.589,28.589,28.589h28.779
                    C76.194,503.698,63.394,490.898,63.394,475.11z"/>
            <path fillOpacity="0.15" fill="#293133" d="M396.349,475.11V101.584l40.519-84.894l-0.728-1.525
                    c-6.81-9.151-20.514-9.151-27.322,0l-41.247,86.419V475.11c0,15.789,12.8,28.589,28.589,28.589h28.779
                    C409.149,503.698,396.349,490.898,396.349,475.11z"/>
            <path fillOpacity="0.15" fill="#293133" d="M229.872,475.11V101.584l40.519-84.894l-0.728-1.525
                    c-6.81-9.151-20.514-9.151-27.322,0l-41.247,86.419V475.11c0,15.789,12.8,28.589,28.589,28.589h28.779
                    C242.671,503.698,229.872,490.898,229.872,475.11z"/>
        </g>
        <g>
            <path fill="#26150C" d="M152.683,100.688c-0.007-0.069-0.022-0.134-0.031-0.201c-0.03-0.23-0.066-0.457-0.115-0.681
                        c-0.018-0.077-0.04-0.153-0.06-0.23c-0.052-0.211-0.111-0.421-0.178-0.625c-0.028-0.083-0.06-0.163-0.09-0.246
                        c-0.072-0.195-0.149-0.386-0.236-0.574c-0.019-0.041-0.031-0.083-0.05-0.124l-41.247-86.417c-0.232-0.486-0.51-0.947-0.832-1.38
                        C105.017,3.72,97.61,0,89.523,0S74.03,3.72,69.203,10.209c-0.322,0.433-0.6,0.894-0.832,1.38L27.123,98.007
                        c-0.02,0.041-0.032,0.083-0.051,0.124c-0.086,0.187-0.164,0.379-0.236,0.574c-0.03,0.082-0.062,0.163-0.09,0.246
                        c-0.069,0.205-0.126,0.414-0.178,0.625c-0.02,0.076-0.042,0.153-0.06,0.23c-0.049,0.224-0.084,0.451-0.115,0.681
                        c-0.009,0.068-0.023,0.134-0.031,0.201c-0.032,0.294-0.05,0.592-0.05,0.895v348.223v25.305c0,20.341,16.549,36.889,36.89,36.889
                        h52.639c20.341,0,36.89-16.548,36.89-36.889v-25.305V101.582C152.733,101.28,152.715,100.981,152.683,100.688z M82.948,19.591
                        c1.657-1.906,4.017-2.987,6.575-2.987c2.559,0,4.919,1.081,6.576,2.987l35.171,73.69H47.777L82.948,19.591z M136.13,475.11
                        c0,11.185-9.101,20.286-20.287,20.286H63.203c-11.186,0-20.287-9.101-20.287-20.286v-17.003h93.213V475.11z M136.13,441.503H42.916
                        v-32.7h93.213V441.503z M136.13,362.849H42.916V188.54h93.213V362.849z M136.13,142.584H42.916v-32.7h93.213V142.584z"/>
            <ellipse fill="#26150C" cx="89.526" cy="275.693" rx="24.551" ry="54.725"/>
            <path fill="#26150C" d="M319.16,100.688c-0.007-0.069-0.022-0.134-0.031-0.201c-0.03-0.23-0.066-0.457-0.115-0.681
                        c-0.018-0.077-0.04-0.153-0.06-0.23c-0.052-0.211-0.111-0.421-0.178-0.625c-0.028-0.083-0.06-0.163-0.09-0.246
                        c-0.072-0.195-0.149-0.386-0.236-0.574c-0.019-0.041-0.031-0.083-0.05-0.124l-41.246-86.417c-0.232-0.486-0.51-0.947-0.832-1.38
                        C271.494,3.72,264.087,0,256,0c-8.087,0-15.493,3.72-20.32,10.209c-0.322,0.433-0.6,0.894-0.832,1.38L193.6,98.007
                        c-0.02,0.041-0.032,0.083-0.051,0.124c-0.086,0.187-0.164,0.379-0.236,0.574c-0.03,0.082-0.062,0.163-0.09,0.246
                        c-0.069,0.205-0.126,0.414-0.178,0.625c-0.02,0.076-0.042,0.153-0.06,0.23c-0.049,0.224-0.084,0.451-0.115,0.681
                        c-0.009,0.068-0.023,0.134-0.031,0.201c-0.032,0.294-0.05,0.592-0.05,0.895v348.223v25.305c0,20.341,16.549,36.889,36.89,36.889
                        h52.639c20.341,0,36.89-16.548,36.89-36.889v-25.305V101.582C319.211,101.28,319.192,100.981,319.16,100.688z M249.425,19.591
                        c1.657-1.907,4.017-2.987,6.575-2.987c2.559,0,4.919,1.081,6.576,2.987l35.171,73.69h-83.494L249.425,19.591z M302.608,475.11
                        c0,11.185-9.101,20.286-20.287,20.286h-52.64c-11.186,0-20.287-9.101-20.287-20.286v-17.003h93.213v17.003H302.608z
                         M302.608,441.503h-93.213v-32.7h93.213V441.503z M302.608,362.849h-93.213V188.54h93.213V362.849z M302.608,142.584h-93.213v-32.7
                        h93.213V142.584z"/>
            <ellipse fill="#26150C" cx="256.002" cy="275.693" rx="24.551" ry="54.725"/>
            <path fill="#26150C" d="M485.638,100.688c-0.007-0.069-0.022-0.134-0.031-0.201c-0.03-0.23-0.066-0.457-0.115-0.681
                        c-0.018-0.077-0.04-0.153-0.06-0.23c-0.052-0.211-0.111-0.421-0.178-0.625c-0.028-0.083-0.06-0.163-0.09-0.246
                        c-0.072-0.195-0.149-0.386-0.236-0.574c-0.019-0.041-0.031-0.083-0.05-0.124l-41.247-86.417c-0.232-0.486-0.51-0.947-0.832-1.38
                        C437.972,3.72,430.565,0,422.478,0c-8.087,0-15.493,3.72-20.32,10.209c-0.322,0.433-0.6,0.894-0.832,1.38l-41.247,86.418
                        c-0.02,0.041-0.032,0.083-0.051,0.124c-0.086,0.187-0.164,0.379-0.236,0.574c-0.03,0.082-0.062,0.163-0.09,0.246
                        c-0.069,0.205-0.126,0.414-0.178,0.625c-0.02,0.076-0.042,0.153-0.06,0.23c-0.049,0.224-0.084,0.451-0.115,0.681
                        c-0.009,0.068-0.023,0.134-0.031,0.201c-0.032,0.294-0.05,0.592-0.05,0.895v348.223v25.305c0,20.341,16.549,36.889,36.89,36.889
                        h52.639c20.341,0,36.89-16.548,36.89-36.889v-25.305V101.582C485.688,101.28,485.67,100.981,485.638,100.688z M415.903,19.591
                        c1.657-1.907,4.017-2.987,6.575-2.987c2.559,0,4.919,1.081,6.576,2.987l35.171,73.69h-83.494L415.903,19.591z M469.085,475.11
                        c0,11.185-9.101,20.286-20.287,20.286h-52.639c-11.186,0-20.287-9.101-20.287-20.286v-17.003h93.213V475.11z M469.085,441.503
                        h-93.213v-32.7h93.213V441.503z M469.085,362.849h-93.213V188.54h93.213V362.849z M469.085,142.584h-93.213v-32.7h93.213V142.584z"
            />
            <ellipse fill="#26150C" cx="422.478" cy="275.693" rx="24.551" ry="54.725"/>
        </g>
    </svg>
}

export {badge1000Tokens, badgeIcon1, badgeIcon2}