import { useState } from "react";
import { logotypeWithIcon, logotypeWithIconMedium } from "../../../../utils/svgs";
import { Link } from "react-router-dom";
import ContactUs from "../contactUs/contactModal";
import FetchClient from "../../../../utils/fetchClient";

const WebsiteHeader = () => {
    const [isNavOpen, setIsNavOpen] = useState(true); 
    const [openContactModal, setOpenContactModal] = useState(false);

    const handleCloseContactModal = () =>{
        setOpenContactModal(false);
       
    }
    const handlePost = (data) => {

        FetchClient({
            method: 'post',
            url: 'contact/us',
            data: {
                email: data.email,
                content: data.message
            }
        }).then(response => {
            window.$notification({
                text: 'Your message sent successfully !',
                variant: 'success',
                position: 'center-top'
            })            
        }).catch((error)=>{
            window.$notification({
            text: 'Oops! There was an error',
            variant: 'error',
            position: 'center-top'
        });
        })
    }
    return(
        <header
        className="sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full text-sm py-4 bg-white dark:bg-slate-700 sm:py-2">
        <nav className="w-full">
            <section className={`${isNavOpen ? "showMenuNav" : "hideMenuNav"} MOBILE-MENU  flex lg:hidden`}>
                <div
                    className={`${isNavOpen ? "hidden" : ""} HAMBURGER-ICON  space-y-2 flex justify-center items-center flex-col w-full`}
                    onClick={() => setIsNavOpen((prev) => !prev)}>
                    <svg className="cursor-pointer " viewBox="0 -0.5 25 25" width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z" 
                    fill={localStorage.getItem("dark-mode") === "dark" ? "#f8fafc" :"#000000"}></path> </g></svg>
                    <a className="font-medium block text-center text-black hover:text-gray-700 dark:text-slate-50 sm:py-3"
                        href="#benefits">Benefits</a>
                    <a className="font-medium block text-center text-black hover:text-gray-700 dark:text-slate-50  sm:py-3"
                        href="#features">Features</a>
                    <a  className="font-medium text-center block text-black hover:text-gray-700 dark:text-slate-50  sm:py-3" href="#pricing">
                        Pricing</a>
                    <a className="font-medium text-center block text-black hover:text-gray-700 dark:text-slate-50  sm:py-3" href="#faq">
                        FAQ</a>
                    <p onClick={() => setOpenContactModal(true)} className="font-medium cursor-pointer dark:text-slate-50  text-center block text-black hover:text-gray-700 sm:py-3" >
                        Contact</p>
                    <a className="flex text-center items-center gap-x-2 font-medium text-jclogo-300 dark:text-jclogo-50 dark:hover:text-jclogo-300 hover:text-jclogo-50 sm:border-s sm:border-white/[.3] sm:my-3 sm:ps-6"
                    href="/login">
                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3"
                            strokeLinecap="round" strokeLinejoin="round">
                            <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/>
                            <circle cx="12" cy="7" r="4"/>
                        </svg>
                        Register / Login
                    </a>
                </div>
                <div className={`${isNavOpen ? "showMenuNav" : "hideMenuNav"} bg-gray-50 dark:bg-slate-700 `}> 
                    <div
                    className="CROSS-ICON absolute flex items-center top-0 right-0 px-8 pt-5"
                    onClick={() => setIsNavOpen(false)} 
                    >
                    <svg className="cursor-pointer" width={40} height={40} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
                    stroke={localStorage.getItem("dark-mode") === "dark" ? "#f8fafc" :"#000000"}><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 7L4 7" fill={localStorage.getItem("dark-mode") === "dark" ? "#f8fafc" :"#000000"} strokeWidth="1.5" strokeLinecap="round"></path> <path d="M20 12L4 12" fill={localStorage.getItem("dark-mode") === "dark" ? "#f8fafc" :"#000000"} strokeWidth="1.5" strokeLinecap="round"></path> <path d="M20 17L4 17" fill={localStorage.getItem("dark-mode") === "dark" ? "#f8fafc" :"#000000"} strokeWidth="1.5" strokeLinecap="round"></path> </g></svg>            </div>
                    <Link to="/">
                        {localStorage.getItem("dark-mode") === "dark" ? logotypeWithIcon("h-10 ml-5 show-main-icon ", "0 0 1400 300") : logotypeWithIconMedium("h-10 ml-5 show-main-icon ", "0 0 1400 300")}
                    </Link>
                </div>
            </section>
            <ul className="DESKTOP-MENU hidden items-center lg:justify-between w-full lg:flex">
                <div className="flex items-center justify-between w-1/2">
                <Link  to="/">
                     { localStorage.getItem("dark-mode") === "dark" ? logotypeWithIcon("h-10 ml-5 show-main-icon", "0 0 1400 300") : logotypeWithIconMedium("h-10 ml-5 show-main-icon", "0 0 1400 300")}
                </Link>
                    <li>
                        <a className="font-semibold text-black dark:text-slate-50 flex sm:my-6 hover:text-gray-700" href="#benefits">Benefits</a>
                    </li>
                    <li>
                        <a className="font-semibold text-black dark:text-slate-50 flex sm:my-6 hover:text-gray-700" href="#features">Features</a>
                    </li>
                    <li>
                        <a className="font-semibold text-black dark:text-slate-50 flex sm:my-6  hover:text-gray-700" href="#pricing">Pricing</a>
                    </li>
                    <li>
                        <a className="font-semibold text-black dark:text-slate-50 flex sm:my-6  hover:text-gray-700" href="#faq">FAQ</a>
                    </li>
                    <li>
                        <p className="font-semibold cursor-pointer text-black dark:text-slate-50 flex sm:my-6  hover:text-gray-700"
                        onClick={() => setOpenContactModal(true)} >Contact</p>
                    </li>

                </div>
                <li className="sm:my-6 pr-5">
                        <a className="flex items-center font-medium dark:hover:text-jclogo-300 dark:text-jclogo-50 text-jclogo-300 hover:text-jclogo-50 sm:border-s sm:border-white/[.3]" href="/login">
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/>
                                <circle cx="12" cy="7" r="4"/>
                            </svg>
                            Register / Login
                        </a>
                    </li>
            </ul>
        </nav>
        <ContactUs isOpen={openContactModal} onClose={()=>handleCloseContactModal()} onConfirm={(data) => handlePost(data)}/>
    </header>
    )
}
export default WebsiteHeader;