import React, {useEffect, useState} from "react";
import {useAuth} from "../utils/authProvider";
import {useNavigate} from "react-router-dom";
import {useLayoutContext} from "./layoutProvider";
import ReactHTMLParser, {domToReact} from "html-react-parser";

import Popper from '../components/popper';
import Modal from "../components/modal";
import Tab from "../components/tab";
import ListMenu from "../components/listmenu"
import {openCloseSidebarPanel} from "../utils/helpers";

import FetchClient from "../utils/fetchClient";
import ReactQuill from "react-quill";

import emptyImage from '../../images/empty-girl-box.svg';
import {useCourseContext} from "../utils/courseContext";
import {badge1000Tokens, badgeIcon2} from "../utils/badges";

let currentColor = "#2196F3";
let logoColor = "#21cbaf";

const Header = (props) => {
    const {setLoader} = useLayoutContext();

    const navigate = useNavigate();
    const {setStore} = useAuth();
    const {header, setHeader} = useLayoutContext();
    const [exercise, setExercise] = useState({parsedContent: ''});
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [exerciseModal, setExerciseModal] = useState();
    const [questionModal, setQuestionModal] = useState();
    const [answerModal, setAnswerModal] = useState();
    const [answerInputs, setAnswerInputs] =
        useState([{content: "", note: ""}]);
    const [userInputs, setUserInputs] =
        useState({name: "", email: ""});
    const [userDetailInputs, setUserCourseFormInputs] =
        useState({});
    const [userProfileModal, setUserProfileModal] = useState();
    const [courseForm, setCourseForm] = useState();
    const [passInputs, setPassInputs] =
        useState({oldPass: "", newPass: "", newPassRepeat: ""});
    const [userPassModal, setUserPassModal] = useState();
    const [change_pass_response_error, setChangePassResponseError] = useState({message: "", customized_error_code: -1});
    const [courseRequestInputs, setCourseRequestInputs] =
        useState({code: ""});
    const [phoneError, setPhoneError] = useState(false);
    const [addCourseModal, setAddCourseModal] = useState();
    const [createCourseInputs, setCreateCourseInputs] =
        useState({
            name: "",
            teacher_id: -1,
            course_description: "",
            teacher_description: "",
            gpt_secret_key: "",
            assistant_selection: "flexible",
            token_usage_per_student: -1
        });
    const [createCourseModal, setCreateCourseModal] = useState();
    const [createCoursePhoneModal, setCreateCoursePhoneModal] = useState();
    // const [courses, setCourses] = useState([]);
    const [phone, setPhone] = useState("");
    // const [selectedCourse, setSelectedCourse] = useState();
    const [course_add_response_error, setCourseAddResponseError] = useState({message: "", customized_error_code: -1});
    const [course_create_response_error, setCreateCourseResponseError] = useState({
        message: "",
        customized_error_code: -1
    });
    const {setSelectedCourse, setCourses, courses, selectedCourse} = useCourseContext();
    const user = props.user;
    const handleLogout = () => {
        setStore("token", "logout");
        localStorage.setItem("user_role", "")
        navigate("/", {replace: true});
    };
    const openAnswerModal = (event, question) => {
        event.preventDefault();
        setSelectedQuestion(question);
        FetchClient({
            method: 'get',
            url: `answers`,
            params: {
                user_id: user.id,
                question_id: question.id,
                course_id: selectedCourse.id
            }
        }).then(response => {
            // openCloseSidebarPanel(false);
            let answer = response.data;
            setAnswerInputs({content: answer.content, note: answer.note});
            answerModal.open();
        }).catch((error) => {
            setAnswerInputs({content: "", note: ""});
            answerModal.open();
        })
    }
    const submitAnswer = async (event) => {
        event.preventDefault();
        questionModal.close();
        let status;
        for (let i = 0; i < answerInputs.length; i++) {
            await new Promise(resolve => setTimeout(resolve, 300));

            try {
                await FetchClient({
                    method: 'post',
                    url: `answers`,
                    data: answerInputs[i]
                }).then((respone) => status = respone?.status);

                // setAnswerInputs([{content: "", note: ""}]);

            } catch (error) {
                console.error(error);
            }
        }
        if (status === 200) {
            window.$notification({
                text: 'Your answers submitted successfully!',
                variant: 'primary',
                position: 'center-top'
            })
        }
    }

    function getExercise(event) {
        event.preventDefault();
        FetchClient({
            method: 'get',
            url: `exercises/` + header.exerciseId,
            params: {
                exercise_id: header.exerciseId,
                course_id: selectedCourse?.id
            }
        }).then(response => {
            // openCloseSidebarPanel(false);
            let newExercise = response.data;
            newExercise.parsedContent =
                ReactHTMLParser(response.data.description, {
                    replace: ({tagName, children}) => {
                        if (tagName === 'html') {
                            return domToReact(children);
                        }
                    }
                });
            setExercise(newExercise);
            exerciseModal.open();
        })
    }

    function getQuestions(event) {
        event.preventDefault();
        FetchClient({
            method: 'get',
            url: `exercise_questions/` + header.exerciseId,
            params: {
                exercise_id: header.exerciseId,
                course_id: selectedCourse?.id
            }
        }).then(response => {
            openCloseSidebarPanel(false);
            let newQuestions = response.data;
            setQuestions(newQuestions);
            questionModal.open();
        })
    }

    const openUserProfileModal = (event) => {
        event.preventDefault();
        setUserInputs({name: user.name, email: user.email});
        setUserCourseFormInputs({});
        userProfileModal.open();
    }
    const handleUserProfileChange = (event) => {
        const {name, value} = event.target;
        setUserInputs(values => ({...values, [name]: value}))
    }
    const handleUserProfileCourseFormChange = (event) => {
        const {name, value} = event.target;
        setUserCourseFormInputs(values => ({...values, [name]: value}))
    }

    const getCourseForm = () => (
        FetchClient({
            method: 'get',
            url: 'course/form/' + selectedCourse.id
        }).then(response => {
            setCourseForm(response.data)
        })
    );

    function submitUserProfile(event) {
        event.preventDefault();
        setLoader(true);
        user.name = userInputs.name;
        FetchClient.put('users/' + user.id, user)
            .then(response => {
                const edited_user = response.data;
                setStore("user", edited_user);
                FetchClient({
                    method: 'post',
                    url: '/course/form/answer',
                    data: {
                        course_id: selectedCourse.id,
                        student_id: user.id,
                        answers: userDetailInputs
                    }
                }).then(response => {
                    window.$notification({
                        text: 'User edited successfully!',
                        variant: 'primary',
                        position: 'center-top'
                    })
                    setLoader(false);
                    window.location.reload();
                });
            });
    }

    const openUserPasswordModal = (event) => {
        event.preventDefault();
        setChangePassResponseError({message: "", customized_error_code: -1});
        setPassInputs({oldPass: "", newPass: "", newPassRepeat: ""});
        userPassModal.open();
    }
    const handleUserPasswordChange = (event) => {
        setChangePassResponseError({message: "", customized_error_code: -1});
        const {name, value} = event.target;
        setPassInputs(values => ({...values, [name]: value}))
    }

    function submitUserPassword(event) {
        event.preventDefault();
        if (passInputs.newPass === passInputs.newPassRepeat) {
            setLoader(true);
            FetchClient({
                method: 'put',
                url: 'users/change_pass/' + user.id,
                params: {
                    user_id: user.id,
                    old_pass: passInputs.oldPass,
                    new_pass: passInputs.newPass
                }
            }).then(response => {
                window.$notification({
                    text: 'Password changed successfully!',
                    variant: 'primary',
                    position: 'center-top'
                })
                setLoader(false);
                setPassInputs({oldPass: "", newPass: "", newPassRepeat: ""});
                userPassModal.close();
            }).catch((error) => {
                setLoader(false);
                if (error.response) {
                    setChangePassResponseError(error.response.data.detail);
                }
            });
        } else {
            setChangePassResponseError({
                message: "New password and repeat password are not the same.",
                customized_error_code: 10000
            });
        }
    }

    const getProfile = () => {
        FetchClient({
            method: 'get',
            url: `profile`,
            data: {}
        }).then(response => {
            const user = response.data;
            setStore("user", user);
            FetchClient({
                method: 'get',
                url: 'user/' + user.id + '/courses',
                params: {
                    user_id: user.id
                }
            }).then(response => {
                var courses = response.data;
                if (courses && courses.length > 0) {
                    setCourses(courses);
                    setSelectedCourse(courses[0])
                    // setStore("course", courses[0]);
                }
            }).catch((error) => {
            });
        }).catch((error) => {
        })
    }
    useEffect(() => {
        setExerciseModal(new Modal("#exerciseModal"));
        setQuestionModal(new Modal("#questionModal"));
        setAnswerModal(new Modal("#answerModal"));
        setUserProfileModal(new Modal("#userProfileModal"));
        setAddCourseModal(new Modal("#addCourseModal"));
        setCreateCourseModal(new Modal("#createCourseModal"));
        setCreateCoursePhoneModal(new Modal("#createCoursePhoneModal"));
        setUserPassModal(new Modal("#userPassModal"));
        new Tab(document.querySelector("#profileModalTabs"));
        new Modal("#logoutModal");
        new Popper("#user-profile-menu", ".popper-ref", ".popper-root", {
            placement: "bottom-end",
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 4],
                    },
                },
            ],
        });
        new Popper("#add-class-menu", ".popper-ref", ".popper-root", {
            placement: "bottom-end",
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 4],
                    },
                },
            ],
        });
        getProfile()
        // getCourseForm();
    }, []);

    useEffect(() => {
        const listMenuWrapper = document.querySelector(`.${"listMenuWrapper"}`);
        if (listMenuWrapper) {
            new ListMenu(listMenuWrapper);
        }
    }, [questions]);


    const handleAnswerFormChange = (index, event) => {
        const {name, value} = event.target;
        const newAnswerInputs = [...answerInputs];
        if (!newAnswerInputs[index]) {
            newAnswerInputs[index] = {user_id: user.id, question_id: questions[index].id, [name]: value};
        } else {
            newAnswerInputs[index] = {
                ...newAnswerInputs[index],
                user_id: user.id,
                question_id: questions[index].id,
                [name]: value
            };
        }
        setAnswerInputs(newAnswerInputs);
    };
    const handleAnswerHTMLChange = (index, content, delta, source, editor) => {
        const newAnswerInputs = [...answerInputs];
        if (!newAnswerInputs[index]) {
            newAnswerInputs[index] = {user_id: user.id, question_id: questions[index].id, content};
        } else {
            newAnswerInputs[index] = {
                ...newAnswerInputs[index],
                user_id: user.id,
                question_id: questions[index].id,
                content
            };
        }
        setAnswerInputs(newAnswerInputs);
    };
    const questionItems = questions.map((q, index) => (
        <div key={q.id} className="flex flex-col">
            <div className="flex-1 mx-4">
                Question&nbsp;{index + 1}&nbsp;: {q.title}
            </div>
            <form className="scrollbar-sm overflow-y-auto sm:px-5">
                <p>
                    <strong> {selectedQuestion ? selectedQuestion.title : ""} </strong>
                </p>
                <div className="mt-4 space-y-4">
                    <label className="block">
                        <span> Enter your answer:</span>
                        <ReactQuill
                            theme="snow"
                            className="h-40 w-full inline-block"
                            value={answerInputs[index] ? answerInputs[index].content : ""}
                            onChange={(content, delta, source, editor) => handleAnswerHTMLChange(index, content, delta, source, editor)}
                        />
                        <br/>
                        <span> Additional Notes: </span>
                        <textarea
                            className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            rows="2"
                            placeholder="Any additional notes?"
                            name="note"
                            value={answerInputs[index] ? answerInputs[index].note : ""}
                            onChange={(event) => handleAnswerFormChange(index, event)}
                        >
                </textarea>
                    </label>
                </div>
            </form>
        </div>
    ));


    useEffect(() => {
        if (user) {
            getUserCourses();
        }
    }, [user]);

    function getUserCourses() {
        FetchClient({
            method: 'get',
            url: 'user/' + user.id + '/courses',
            params: {
                user_id: user.id
            }
        }).then(response => {
            if (response?.data !== undefined && response?.data?.length > 0) {
                setCourses(response.data);
                setSelectedCourse(response.data[0])
                // setStore("course", response.data[0]);
            }
        }).catch((error) => {
        });
    }

    useEffect(() => {
        setSelectedCourse(selectedCourse);
    }, [courses]);
    const openAddCourseModal = (event) => {
        event.preventDefault();
        setCourseAddResponseError({message: "", customized_error_code: -1});
        addCourseModal.open();
    }
    const changeCourse = (event) => {
        const index = event.target.selectedIndex;
        const optionElement = event.target.childNodes[index];
        const optionElementId = optionElement.getAttribute('id');
        setSelectedCourse(courses[optionElementId]);
        setStore("course", courses[optionElementId]);
        if (courses[optionElementId].role === "student" && (window.location.pathname.includes("course")
            || window.location.pathname.includes("user_list")
            || window.location.pathname.includes("exercise_list"))) {
            window.location.replace("/");
        } else {
            if (window.location.pathname.includes("user_list")) {
                window.location.replace("/user_list");
            } else if (window.location.pathname.includes("exercise_list")) {
                window.location.replace("/exercise_list");
            } else if (window.location.pathname.includes("chats")) {
                window.location.replace("/chats");
            } else {
                window.location.reload();
            }
        }
    };
    const handleCourseRequestChange = (event) => {
        const {name, value} = event.target;
        setCourseRequestInputs(values => ({...values, [name]: value}));
    }
    const keyupHandlerJoinCourseForm = (event) => {
        if (event.key === 'Enter') {
            submitCourseRequest(event);
        }
    };

    function submitCourseRequest(event) {
        event.preventDefault();
        FetchClient({
            method: 'post',
            url: 'request_course/',
            params: {
                course_code: courseRequestInputs.code,
                user_id: user.id
            }
        }).then(response => {
            window.$notification({
                text: 'Course request has been successfully submitted!',
                variant: 'primary',
                position: 'center-top'
            })
            setLoader(false);
            setCourseRequestInputs({code: ""});
            getUserCourses();
            addCourseModal.close();
        }).catch((error) => {
            if (error.response) {
                setCourseAddResponseError(error.response.data.detail);
            }
        });
    }

    const openCreateCourseModal = (event) => {
        event.preventDefault();
        setCreateCourseResponseError({message: "", customized_error_code: -1});
        createCourseModal.open();
    }
    const openRequestCourseModal = (event) => {
        event.preventDefault();
        setCreateCourseResponseError({message: "", customized_error_code: -1});
        createCoursePhoneModal.open();
    }
    const handleCreateCourseChange = (event) => {
        const {name, value} = event.target;
        setCreateCourseInputs(values => ({...values, [name]: value}));
    }

    function submitCreateCourse(event) {
        event.preventDefault();
        createCourseInputs.teacher_id = user.id;
        FetchClient({
            method: 'post',
            url: 'course/create',
            data: createCourseInputs
        }).then(response => {
            window.$notification({
                text: 'Course created successfully!',
                variant: 'primary',
                position: 'center-top'
            })
            setLoader(false);
            setCreateCourseInputs({
                name: "",
                teacher_id: -1,
                course_description: "",
                teacher_description: "",
                gpt_secret_key: "",
                assistant_selection: "flexible",
                token_usage_per_student: -1
            });
            getUserCourses();
            createCourseModal.close();
            // window.location.reload();
        }).catch((error) => {
            if (error.response) {
                setCreateCourseResponseError(error.response.data.detail);
            }
        });
        // else if (user?.user_role === "student"){
        //     createCoursePhoneModal.open();
        // }
    }

    const keydownHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitCreateCourseWithPhone(event);
        }
    };

    function submitCreateCourseWithPhone(event) {
        event.preventDefault();
        if (phone === "") {
            setPhoneError(true)
        } else {
            const requestData = {id: user.id, email: user.email, phoneNumber: phone}
            FetchClient({
                method: 'put',
                url: 'users/role/teacher/request',
                data: requestData
            }).then(response => {
                window.$notification({
                    text: 'Your request is sent successfully, our team will call you very soon !',
                    variant: 'primary',
                    position: 'center-top'
                })
                setLoader(false);
                setPhoneError(false)
                getUserCourses();
                createCoursePhoneModal.close();
                // createCourseModal.open();
                // window.location.reload();
            }).catch((error) => {
                if (error.response) {
                    setCreateCourseResponseError(error.response.data.detail);
                }
            });
        }
    }

    return <nav className="header print:hidden">
        <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
            <div className="flex w-full items-center justify-between">
                <div className="flex stdent-button-container cursor-pointer items-center space-x-4 font-inter">
                    <div className="h-7 w-7" id="sidebar-toggle-btn">
                        <button
                            className="sidebar-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-jclogo-200 outline-none focus:outline-none dark:text-jclogo-50/80"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    {window.location.pathname.includes("/chats/chat/") &&
                        <>
                            <div>
                                <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                                    {header.title}
                                </p>
                                <p className="mt-0.5 text-xs">
                                    {header.subtitle !== "" ? header.subtitle : ""}
                                </p>
                            </div>
                            <div className="flex">
                                {header.exerciseId != -1 ?
                                    <button
                                        className="btn mx-0.5  stdent-button border border-warning/30 bg-warning/10 font-medium text-warning  hover:bg-warning/20 focus:bg-warning/20 active:bg-warning/25"
                                        data-toggle="modal"
                                        data-target="#exerciseModal"
                                        onClick={getExercise}
                                    >
                                        Exercise
                                    </button>
                                    :
                                    <></>
                                }
                                {header.exerciseId != -1 ?
                                    <button
                                        className="btn mx-0.5 border stdent-button border-success/30 bg-success/10 font-medium text-success  hover:bg-success/20 focus:bg-success/20 active:bg-success/25"
                                        data-toggle="modal"
                                        data-target="#questionModal"
                                        onClick={getQuestions}
                                    >
                                        Questions
                                    </button>
                                    :
                                    <></>
                                }
                            </div>
                        </>}
                </div>

                <div className="-mr-1.5 flex items-center header-items-container justify-end space-x-2">
                    <select onChange={changeCourse}
                            className={"flex header-select-course form-select rounded-full border border-jclogo-300 bg-white px-2 py-1 pr-6 hover:border-jclogo-400 focus:border-jclogo-400 dark:border-jclogo-100 dark:bg-navy-700 dark:hover:border-jclogo-200 dark:focus:border-jclogo-50 "
                                + (props?.course ? (props?.course.role === 'teacher' ? 'text-jclogo-500 dark:text-jclogo-100' : props?.course.status === 'subscribed' ? 'text-lime-500' : props?.course.status === 'requested' ? 'text-red-500' : '') : '')}>
                        {
                            courses.map((c, index) => (
                                <option key={c.id} id={index}
                                        selected={props?.course ? c.name == props?.course.name : false}
                                        className={c.role === 'teacher' ? 'text-sky-500' : c.status === 'subscribed' ? 'text-lime-500' : c.status === 'requested' ? 'text-red-500' : ''}
                                >
                                    {c.name + " (code: " + c.code + ")" + (c.status === 'requested' ? ' (Waiting)' : '')}
                                </option>
                            ))
                        }
                    </select>
                    <div className="flex">
                        <div id="add-class-menu" className=" header-plus-course">
                            <button
                                className="popper-ref btn h-8 w-8 p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 text-slate-500 dark:text-navy-100"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H12.75L12.75 15C12.75 15.4142 12.4142 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15V12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H11.25L11.25 9C11.25 8.58579 11.5858 8.25 12 8.25Z"
                                          fill={logoColor}/>
                                </svg>
                            </button>
                            <div className="popper-root">
                                <div
                                    className="popper-box rounded-md border border-slate-150 bg-white py-1.5 font-inter dark:border-navy-500 dark:bg-navy-700">
                                    <ul>
                                        <li>
                                            <a
                                                className="flex cursor-pointer h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                                                onClick={(event) => openAddCourseModal(event)}

                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 text-slate-500 dark:text-navy-100"
                                                    fill="currentColor"
                                                    viewBox="0 0 340 340"
                                                >
                                                    <g>
                                                        <path d="M280.988,0.001h-86.496c-4.136,0-7.5,3.364-7.5,7.5v20c0,4.135,3.364,7.5,7.5,7.5h58.996v218.487H35
                                                    V35h58.996c4.136,0,7.5-3.364,7.5-7.5v-20c0-4.135-3.364-7.5-7.5-7.5H7.5C3.365,0,0,3.365,0,7.5v273.488c0,4.135,3.365,7.5,7.5,7.5
                                                    h273.488c4.135,0,7.5-3.365,7.5-7.5V7.501C288.488,3.365,285.124,0.001,280.988,0.001z"/>
                                                        <path d="M87.489,97.032c-2.003,0-3.887,0.78-5.303,2.197l-14.143,14.142c-1.416,1.416-2.197,3.3-2.197,5.303
                                                    c0,2.003,0.78,3.886,2.197,5.304l70.898,70.899c1.417,1.416,3.3,2.196,5.303,2.196s3.886-0.78,5.303-2.196l70.898-70.898
                                                    c1.416-1.417,2.197-3.3,2.197-5.303c0-2.004-0.78-3.887-2.197-5.304l-14.143-14.142c-1.416-1.416-3.3-2.196-5.303-2.196
                                                    c-2.003,0-3.887,0.78-5.303,2.197l-33.952,33.952V25.43c0-4.136-3.364-7.5-7.5-7.5h-20c-4.136,0-7.5,3.364-7.5,7.5v107.751
                                                    L92.793,99.229C91.376,97.813,89.493,97.032,87.489,97.032z"/>
                                                    </g>
                                                </svg>
                                                <span>Join a Course</span>
                                            </a>
                                        </li>
                                        {user?.user_role === "student" || user?.user_role === "human_teacher_assistant" ?
                                            <li className={`${(user != null && user !== undefined && (user.user_role === "student" || user.user_role === 'teacher' || user.user_role === 'admin')) ? '' : 'hidden'}`}>
                                                <a
                                                    className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                                                    onClick={(event) => openRequestCourseModal(event)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 text-slate-500 dark:text-navy-100"
                                                        fill="currentColor"
                                                        viewBox="0 0 22 22"
                                                    >
                                                        <g>
                                                            <path
                                                                d="M21 14v5c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h5v2H5v14h14v-5h2z"/>
                                                            <path d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4"/>
                                                        </g>
                                                    </svg>
                                                    <span>Request to Create a Course</span>
                                                </a>
                                            </li>

                                            :
                                            <li className={`${(user != null && user !== undefined && (user.user_role === 'teacher' || user.user_role === 'admin')) ? '' : 'hidden'}`}>
                                                <a href="#"
                                                   className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                                                   onClick={(event) => openCreateCourseModal(event)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 text-slate-500 dark:text-navy-100"
                                                        fill="currentColor"
                                                        viewBox="0 0 22 22"
                                                    >
                                                        <g>
                                                            <path
                                                                d="M21 14v5c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h5v2H5v14h14v-5h2z"/>
                                                            <path d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4"/>
                                                        </g>
                                                    </svg>
                                                    <span>Create a Course</span>
                                                </a>
                                            </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div id="badge" className="inline-flex hidden">
                            <button className="btn relative h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                                {badgeIcon2("h-5", "0 0 64 64")}
                            </button>
                        </div>

                        <div id="notification-wrapper" className="inline-flex">
                            <button
                                id="notification-ref"
                                className="btn relative h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 text-slate-500 dark:text-navy-100"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M15.375 17.556h-6.75m6.75 0H21l-1.58-1.562a2.254 2.254 0 01-.67-1.596v-3.51a6.612 6.612 0 00-1.238-3.85 6.744 6.744 0 00-3.262-2.437v-.379c0-.59-.237-1.154-.659-1.571A2.265 2.265 0 0012 2c-.597 0-1.169.234-1.591.65a2.208 2.208 0 00-.659 1.572v.38c-2.621.915-4.5 3.385-4.5 6.287v3.51c0 .598-.24 1.172-.67 1.595L3 17.556h12.375zm0 0v1.11c0 .885-.356 1.733-.989 2.358A3.397 3.397 0 0112 22a3.397 3.397 0 01-2.386-.976 3.313 3.313 0 01-.989-2.357v-1.111h6.75z"
                                    />
                                </svg>
                            </button>
                            <div id="notification-box" className="popper-root">
                                <div
                                    className="notification-tab-wrapper popper-box mx-4 mt-1 flex max-h-[calc(100vh-6rem)] w-[calc(100vw-2rem)] flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark sm:m-0 sm:w-80"
                                >
                                    <div
                                        className="rounded-t-lg bg-slate-100 text-slate-600 dark:bg-navy-800 dark:text-navy-200"
                                    >
                                        <div className="flex items-center justify-between px-4 pt-2">
                                            <div className="flex items-center space-x-2">
                                                <h3
                                                    className="font-medium text-slate-700 dark:text-navy-100"
                                                >
                                                    Notifications
                                                </h3>
                                                <div
                                                    className="badge h-5 rounded-full bg-primary/10 px-1.5 text-primary dark:bg-accent-light/15 dark:text-accent-light"
                                                >
                                                    0
                                                </div>
                                            </div>

                                            {/*<button*/}
                                            {/*    className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"*/}
                                            {/*>*/}
                                            {/*    <svg*/}
                                            {/*        xmlns="http://www.w3.org/2000/svg"*/}
                                            {/*        className="h-4.5 w-4.5"*/}
                                            {/*        fill="none"*/}
                                            {/*        viewBox="0 0 24 24"*/}
                                            {/*        stroke="currentColor"*/}
                                            {/*        strokeWidth="1.5"*/}
                                            {/*    >*/}
                                            {/*        <path*/}
                                            {/*            strokeLinecap="round"*/}
                                            {/*            strokeLinejoin="round"*/}
                                            {/*            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"*/}
                                            {/*        />*/}
                                            {/*        <path*/}
                                            {/*            strokeLinecap="round"*/}
                                            {/*            strokeLinejoin="round"*/}
                                            {/*            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"*/}
                                            {/*        />*/}
                                            {/*    </svg>*/}
                                            {/*</button>*/}
                                        </div>

                                        <div
                                            className="tabs is-scrollbar-hidden flex shrink-0 overflow-x-auto px-3"
                                        >
                                            <button
                                                className="tab btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5"
                                                data-active-class="border-primary dark:border-accent text-primary dark:text-accent-light"
                                                data-default-class="border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                                data-target="#notification-all"
                                            >
                                                <span>All</span>
                                            </button>
                                            <button
                                                className="tab btn shrink-0 rounded-none border-b-2 px-3.5 py-2.5"
                                                data-active-class="border-primary dark:border-accent text-primary dark:text-accent-light"
                                                data-default-class="border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                                data-target="#notification-chats"
                                            >
                                                <span>Chats</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex flex-col overflow-hidden">
                                        <div
                                            className="tab-content tab-shift-left is-scrollbar-hidden space-y-4 overflow-y-auto px-4 py-4"
                                            id="notification-all"
                                        >
                                            {/*<div className="flex items-center space-x-3">*/}
                                            {/*    <div*/}
                                            {/*        className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-secondary/10 dark:bg-secondary-light/15"*/}
                                            {/*    >*/}
                                            {/*        <i*/}
                                            {/*            className="fa fa-user-edit text-secondary dark:text-secondary-light"*/}
                                            {/*        ></i>*/}
                                            {/*    </div>*/}
                                            {/*    <div>*/}
                                            {/*        <p*/}
                                            {/*            className="font-medium text-slate-600 dark:text-navy-100"*/}
                                            {/*        >*/}
                                            {/*            User Photo Changed*/}
                                            {/*        </p>*/}
                                            {/*        <div*/}
                                            {/*            className="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300"*/}
                                            {/*        >*/}
                                            {/*            John Doe changed his avatar photo*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="flex items-center space-x-3">*/}
                                            {/*    <div*/}
                                            {/*        className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-warning/10 dark:bg-warning/15"*/}
                                            {/*    >*/}
                                            {/*        <svg*/}
                                            {/*            xmlns="http://www.w3.org/2000/svg"*/}
                                            {/*            className="h-5 w-5 text-warning"*/}
                                            {/*            fill="none"*/}
                                            {/*            viewBox="0 0 24 24"*/}
                                            {/*            stroke="currentColor"*/}
                                            {/*            strokeWidth="1.5"*/}
                                            {/*        >*/}
                                            {/*            <path*/}
                                            {/*                strokeLinecap="round"*/}
                                            {/*                strokeLinejoin="round"*/}
                                            {/*                d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"*/}
                                            {/*            />*/}
                                            {/*        </svg>*/}
                                            {/*    </div>*/}
                                            {/*    <div>*/}
                                            {/*        <p*/}
                                            {/*            className="font-medium text-slate-600 dark:text-navy-100"*/}
                                            {/*        >*/}
                                            {/*            THU, May 11, 2021*/}
                                            {/*        </p>*/}
                                            {/*        <div*/}
                                            {/*            className="mt-1 flex text-xs text-slate-400 dark:text-navy-300"*/}
                                            {/*        >*/}
                                            {/*            <span className="shrink-0">10:00 - 11:30</span>*/}
                                            {/*            <div*/}
                                            {/*                className="mx-2 my-1 w-px bg-slate-200 dark:bg-navy-500"*/}
                                            {/*            ></div>*/}
                                            {/*            <span className="line-clamp-1"*/}
                                            {/*            >Interview, Konnor Guzman*/}
                                            {/*            </span>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="mt-8 pb-8 text-center">
                                                <img
                                                    className="mx-auto w-36"
                                                    src={emptyImage}
                                                    alt="image"
                                                />
                                                <div className="mt-5">
                                                    <p
                                                        className="text-base font-semibold text-slate-700 dark:text-navy-100"
                                                    >
                                                        No any notification
                                                    </p>
                                                    <p className="text-slate-400 dark:text-navy-300">
                                                        There are no unread notification
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="notification-chats"
                                            className="tab-content tab-shift-left is-scrollbar-hidden space-y-4 overflow-y-auto px-4 py-4"
                                        >
                                            <div className="mt-8 pb-8 text-center">
                                                <img
                                                    className="mx-auto w-36"
                                                    src={emptyImage}
                                                    alt="image"
                                                />
                                                <div className="mt-5">
                                                    <p
                                                        className="text-base font-semibold text-slate-700 dark:text-navy-100"
                                                    >
                                                        No any notification
                                                    </p>
                                                    <p className="text-slate-400 dark:text-navy-300">
                                                        There are no unread notification
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="user-profile-menu" className="inline-flex">
                            <button
                                className="popper-ref btn h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5.5 w-5.5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    />
                                </svg>
                            </button>

                            <div className="popper-root">
                                <div
                                    className="popper-box rounded-md border border-slate-150 bg-white py-1.5 font-inter dark:border-navy-500 dark:bg-navy-700"
                                >
                                    <ul>
                                        <li>
                                            <a
                                                href="#"
                                                className="flex darkmode-toggle h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                                                onClick={(event) => event.preventDefault()}
                                            >
                                                <svg
                                                    className="darkmode-moon mt-px h-5 w-5 text-amber-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M11.75 3.412a.818.818 0 01-.07.917 6.332 6.332 0 00-1.4 3.971c0 3.564 2.98 6.494 6.706 6.494a6.86 6.86 0 002.856-.617.818.818 0 011.1 1.047C19.593 18.614 16.218 21 12.283 21 7.18 21 3 16.973 3 11.956c0-4.563 3.46-8.31 7.925-8.948a.818.818 0 01.826.404z"
                                                    />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="darkmode-sun mt-px h-5 w-5 text-amber-400 !ml-0"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <span>Dark/Light Mode</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="flex monochrome-toggle h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                                                onClick={(event) => event.preventDefault()}
                                            >
                                                <i
                                                    className="fa-solid fa-palette bg-gradient-to-r from-sky-400 to-blue-600 bg-clip-text text-lg font-semibold text-transparent"
                                                ></i>
                                                <span>Monochrome/Colorful</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                                                onClick={(event) => openUserProfileModal(event)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mt-px h-5 w-5"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                                <span>User Profile</span></a
                                            >
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                                                onClick={(event) => openUserPasswordModal(event)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mt-px h-5 w-5"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                                    />
                                                </svg>
                                                <span>Change Password</span></a
                                            >
                                        </li>
                                        <li>
                                            <a
                                                className="flex cursor-pointer h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide text-error outline-none transition-all hover:bg-error/20 focus:bg-error/20"
                                                data-toggle="modal"
                                                data-target="#logoutModal"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mt-px h-5 w-5"
                                                    fill="none"
                                                    viewBox="0 0 48 48"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                >
                                                    <path fill="#D50000"
                                                          d="M24,6C14.1,6,6,14.1,6,24s8.1,18,18,18s18-8.1,18-18S33.9,6,24,6z M24,10c3.1,0,6,1.1,8.4,2.8L12.8,32.4 C11.1,30,10,27.1,10,24C10,16.3,16.3,10,24,10z M24,38c-3.1,0-6-1.1-8.4-2.8l19.6-19.6C36.9,18,38,20.9,38,24C38,31.7,31.7,38,24,38 z"/>
                                                </svg>
                                                <span>Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="logoutModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-px h-28 w-28"
                    fill="none"
                    viewBox="0 0 48 48"
                    stroke="currentColor"
                    strokeWidth="0"
                >
                    <path fill="#D50000"
                          d="M24,6C14.1,6,6,14.1,6,24s8.1,18,18,18s18-8.1,18-18S33.9,6,24,6z M24,10c3.1,0,6,1.1,8.4,2.8L12.8,32.4 C11.1,30,10,27.1,10,24C10,16.3,16.3,10,24,10z M24,38c-3.1,0-6-1.1-8.4-2.8l19.6-19.6C36.9,18,38,20.9,38,24C38,31.7,31.7,38,24,38 z"/>
                </svg>

                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Logout
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to logout?
                    </p>
                    <div className="space-x-2 text-right mt-4">
                        <button
                            data-close-modal
                            onClick={handleLogout}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            Yes, Logout
                        </button>
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="exerciseModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-[80%] flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-4 dark:bg-navy-700 sm:px-5"
            >
                <div className="mt-4">
                    <div className="w-full flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="h-11 w-11"
                             fill="none"
                             viewBox="0 0 50 50">
                            <polygon fill={currentColor} fillOpacity=".5" points="40,45 8,45 8,3 30,3 40,13"/>
                            <polygon fill={currentColor} fillOpacity="1" points="38.5,14 29,14 29,4.5"/>
                            <g fill={currentColor} fillOpacity="1">
                                <rect x="16" y="21" width="17" height="2"/>
                                <rect x="16" y="25" width="13" height="2"/>
                                <rect x="16" y="29" width="17" height="2"/>
                                <rect x="16" y="33" width="13" height="2"/>
                            </g>
                        </svg>
                        <h2 className="text-2xl font-bold leading-7 text-slate-700 dark:text-navy-100">
                            Exercise
                        </h2>
                    </div>
                    <div className="my-4 h-px  bg-slate-200 dark:bg-navy-500"></div>
                    <p className="mt-2 text-slate-700 dark:text-navy-100">
                        {exercise ? exercise.parsedContent : ''}
                    </p>
                    <div className="space-x-2 text-right mt-4">
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            OK, thanks
                        </button>
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="questionModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-[80%] flex-col items-center overflow-y-auto rounded-lg bg-white dark:bg-navy-700"
            >
                <div className="card">
                    <div
                        className="flex border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5 items-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="h-11 w-11"
                             fill="none"
                             viewBox="0 0 50 50">
                            <g fill={currentColor} fillOpacity=".5">
                                <polygon points="17.8,18.1 10.4,25.4 6.2,21.3 4,23.5 10.4,29.9 20,20.3"/>
                                <polygon points="17.8,5.1 10.4,12.4 6.2,8.3 4,10.5 10.4,16.9 20,7.3"/>
                                <polygon points="17.8,31.1 10.4,38.4 6.2,34.3 4,36.5 10.4,42.9 20,33.3"/>
                            </g>
                            <g fill={currentColor} fillOpacity="1">
                                <rect x="24" y="22" width="20" height="4"/>
                                <rect x="24" y="9" width="20" height="4"/>
                                <rect x="24" y="35" width="20" height="4"/>
                            </g>
                        </svg>
                        <h2
                            className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base"
                        >
                            Questions
                        </h2>
                    </div>
                    <div className="listMenuWrapper" data-active-list-menu="listmenu_1">
                        <div className="p-4 sm:px-5 listmenu">
                            <ul className="space-y-1.5 font-inter font-medium">
                                {questionItems}
                            </ul>
                        </div>
                    </div>
                    <div className="space-x-2 p-2 text-right">
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        >
                            Close
                        </button>
                        <button
                            onClick={submitAnswer}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="answerModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Submit Your Answer
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <p><strong>
                        {selectedQuestion ? selectedQuestion.title : ""}
                    </strong></p>
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span> Enter your answer:</span>
                            <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                        value={answerInputs.content}
                                        onChange={handleAnswerHTMLChange}
                            />
                            <br/>
                            <span> Additional Notes: </span>
                            <textarea
                                className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                rows="2" placeholder="Any additional notes?"
                                name="note"
                                value={answerInputs.note}
                                onChange={handleAnswerFormChange}
                            >
                            </textarea>
                        </label>
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={submitAnswer}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div
            id="userProfileModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Profile Information
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    id="profileModalTabs"
                    data-active-tab="#profileModalProfileTab"
                    className="tabs flex flex-col"
                >
                    <div className="scrollbar-sm overflow-y-auto overflow-x-auto">
                        <div className="border-b-2 border-slate-150 dark:border-navy-500">
                            <div className="tabs-list -mb-0.5 flex">
                                <button
                                    className="tab btn shrink-0 space-x-2 rounded-none border-b-2 px-3 py-2 font-medium"
                                    data-active-class="border-primary dark:border-accent text-primary dark:text-accent-light"
                                    data-default-class="border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                    data-target="#profileModalProfileTab"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4.5 w-4.5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                        />
                                    </svg>
                                    <span>General</span>
                                </button>
                                <button
                                    className="tab btn shrink-0 space-x-2 rounded-none border-b-2 px-3 py-2 font-medium"
                                    data-active-class="border-primary dark:border-accent text-primary dark:text-accent-light"
                                    data-default-class="border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                    data-target="#profileModalDetailTab"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4.5 w-4.5"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M16 2H8C4.691 2 2 4.691 2 8v13a1 1 0 0 0 1 1h13c3.309 0 6-2.691 6-6V8c0-3.309-2.691-6-6-6zm4 14c0 2.206-1.794 4-4 4H4V8c0-2.206 1.794-4 4-4h8c2.206 0 4 1.794 4 4v8z"/>
                                        <path d="M7 9h10v2H7zm0 4h7v2H7z"/>
                                    </svg>
                                    <span>Details</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="tab-content tab-shift-left" id="profileModalProfileTab">
                            <form className="px-4 py-4 sm:px-5">
                                <div className="mt-4 space-y-4">
                                    <label className="block">
                                        <span>Name:</span>
                                        <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            placeholder="Your Name"
                                            name="name"
                                            value={userInputs.name}
                                            onChange={handleUserProfileChange}
                                            type={"text"}
                                        />
                                    </label>
                                    <label className="block">
                                        <span>Email:</span>
                                        <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-navy-100 dark:bg-navy-500 px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            placeholder="Your Email"
                                            name="email"
                                            value={userInputs.email}
                                            disabled={true}
                                            onChange={handleUserProfileChange}
                                            type={"text"}
                                        />
                                    </label>
                                </div>
                            </form>
                        </div>
                        <div className="tab-content tab-shift-left scrollbar-sm overflow-y-scroll max-h-96"
                             id="profileModalDetailTab">
                            <form className="px-4 py-4 sm:px-5">
                                <div className="grid grid-cols-2 gap-4 mt-4">
                                    <label className="block">
                                        <span>What is your sex?</span>
                                        <select name="sex"
                                                onChange={handleUserProfileCourseFormChange}
                                                value={userDetailInputs.sex}
                                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        >
                                            <option hidden disabled selected value=""> -- select an option --</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="noanswer">Prefer not to answer</option>
                                        </select>
                                    </label>
                                    <label className="block">
                                        <span>What is your age?</span>
                                        <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            placeholder="Your Age"
                                            name="age"
                                            value={userDetailInputs.age}
                                            onChange={handleUserProfileCourseFormChange}
                                            type={"text"}
                                        />
                                    </label>
                                    <label className="block">
                                        <span>What is your gender you identified with?</span>
                                        <select name="gender" onChange={handleUserProfileCourseFormChange}
                                                value={userDetailInputs.gender}
                                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        >
                                            <option hidden disabled selected value=""> -- select an option --</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="others">Others</option>
                                            <option value="noanswer">Prefer not to answer</option>
                                        </select>
                                    </label>
                                    <label className="block">
                                        <span>Please share your GPA to the best of your recollection.</span>
                                        <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            placeholder="Your GPA"
                                            name="gpa"
                                            value={userDetailInputs.gpa}
                                            onChange={handleUserProfileCourseFormChange}
                                            type={"text"}
                                        />
                                    </label>
                                </div>
                                <div className="grid grid-cols-1 gap-4 mt-2">
                                    <label className="block">
                                        <span>Which level below do you believe best describes your family’s economic status?</span>
                                        <select name="quantile" onChange={handleUserProfileCourseFormChange}
                                                value={userDetailInputs.quantile}
                                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        >
                                            <option hidden disabled selected value=""> -- select an option --</option>
                                            <option value="bottom_25">Bottom 25%</option>
                                            <option value="26_50">26-50%</option>
                                            <option value="51_75">51-75%</option>
                                            <option value="76_90">76-90%</option>
                                            <option value="top_90">Top 10%</option>
                                        </select>
                                    </label>
                                    <label className="block">
                                        <span>How knowledgeable do you consider yourself regarding AI and its applications, such as ChatGPT?</span>
                                        <select name="knowledge" onChange={handleUserProfileCourseFormChange}
                                                value={userDetailInputs.knowledge}
                                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        >
                                            <option hidden disabled selected value=""> -- select an option --</option>
                                            <option value="Not_knowledgeable">Not knowledgeable at all</option>
                                            <option value="Slightly_knowledgeable">Slightly knowledgeable</option>
                                            <option value="Moderately_knowledgeable">Moderately knowledgeable</option>
                                            <option value="Very_knowledgeable">Very knowledgeable</option>
                                            <option value="Extremely_knowledgeable">Extremely knowledgeable</option>
                                        </select>
                                    </label>
                                    <label className="block">
                                        <span>How frequently do you use ChatGPT? If you have never used it, please select 'Never'</span>
                                        <select name="frequency" onChange={handleUserProfileCourseFormChange}
                                                value={userDetailInputs.frequency}
                                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        >
                                            <option hidden disabled selected value=""> -- select an option --</option>
                                            <option value="never">Never</option>
                                            <option value="once_twice">Only once or twice</option>
                                            <option value="once_few_weeks">Once every few weeks</option>
                                            <option value="everyweek">Every week</option>
                                            <option value="everyday">Every day</option>
                                        </select>
                                    </label>
                                    <label className="block">
                                        <span>how useful do you find AI tools like ChatGPT?</span>
                                        <select name="usefulness" onChange={handleUserProfileCourseFormChange}
                                                value={userDetailInputs.usefulness}
                                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        >
                                            <option hidden disabled selected value=""> -- select an option --</option>
                                            <option value="Not_Useful">Not Useful at All</option>
                                            <option value="Slightly_Useful">Slightly Useful</option>
                                            <option value="Moderately_Useful">Moderately Useful</option>
                                            <option value="Very_Useful">Very Useful</option>
                                            <option value="Extremely_Useful">Extremely Useful</option>
                                        </select>
                                    </label>
                                    <label className="block">
                                        <span>What are your feelings about using an AI learning platform like this website?</span>
                                        <textarea
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            placeholder="Your Content"
                                            name="feelings"
                                            value={userDetailInputs.feelings}
                                            onChange={handleUserProfileCourseFormChange}
                                        />
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="my-4 h-px  bg-slate-200 dark:bg-navy-500"></div>
                <div className="space-x-2 text-right pr-4 pb-4">
                    <button
                        className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => {
                            event.preventDefault();
                            userProfileModal.close();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={submitUserProfile}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>

        <div
            id="userPassModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Porfile Information
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Old Password:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Old Password"
                                name="oldPass"
                                value={passInputs.oldPass}
                                onChange={handleUserPasswordChange}
                                type={"password"}
                            />
                        </label>
                        <label className="block">
                            <span>New Password:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="New Password"
                                name="newPass"
                                value={passInputs.newPass}
                                onChange={handleUserPasswordChange}
                                type={"password"}
                            />
                        </label>
                        <label className="block">
                            <span>Repeat New Password:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Repeat New Password"
                                name="newPassRepeat"
                                value={passInputs.newPassRepeat}
                                onChange={handleUserPasswordChange}
                                type={"password"}
                            />
                        </label>
                        {change_pass_response_error.customized_error_code !== -1 ?
                            <span
                                className="text-center text-tiny+ text-error">{change_pass_response_error.message}</span>
                            : ""
                        }
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={submitUserPassword}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div
            id="addCourseModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Enter Course Code
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Code:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="xxxxxxxx"
                                name="code"
                                value={courseRequestInputs.code}
                                onKeyUp={(e) => keyupHandlerJoinCourseForm(e)}
                                onChange={handleCourseRequestChange}
                                type={"text"}
                            />
                        </label>
                        {course_add_response_error.customized_error_code !== -1 ?
                            <span
                                className="text-center text-tiny+ text-error">{course_add_response_error.message}</span>
                            : ""
                        }
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={submitCourseRequest}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="createCourseModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Create a New Course
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Title:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="e.g. Organizational Behavior"
                                name="name"
                                value={createCourseInputs.name}
                                onChange={handleCreateCourseChange}
                                type={"text"}
                            />
                        </label>
                        <label className="block">
                            <span>Course Description:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="course_description"
                                value={createCourseInputs.course_description}
                                onChange={handleCreateCourseChange}
                                type={"text"}
                            />
                        </label>
                        <label className="block">
                            <span>Introduce Teacher:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="teacher_description"
                                value={createCourseInputs.teacher_description}
                                onChange={handleCreateCourseChange}
                                type={"text"}
                            />
                        </label>
                        <label className="block">
                            <span>Assistant Selection Method:</span>
                            <select name="assistant_selection" onChange={handleCreateCourseChange}
                                    value={createCourseInputs.assistant_selection}
                                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option value="random">Random - Assistant randomly is assigned to the chat</option>
                                <option value="flexible">Flexible - Students select the assistant themselves</option>
                            </select>
                        </label>
                        <label className="block hidden">
                            <span>GPT Secret Key:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="e.g. sk-mO0qIODSOVhhan2xBXIUAFJNpyre2Zwo9w595TeDOGc"
                                name="gpt_secret_key"
                                value={createCourseInputs.gpt_secret_key}
                                onChange={handleCreateCourseChange}
                                type={"text"}
                            />
                        </label>
                        {course_create_response_error.customized_error_code !== -1 ?
                            <span
                                className="text-center text-tiny+ text-error">{course_create_response_error.message}</span>
                            : ""
                        }
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={submitCreateCourse}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="createCoursePhoneModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Request a course:
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Phone Number:</span>
                            <input
                                className="form-input number-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="pnohe number to register as a teacher"
                                name="name"
                                value={phone}
                                onKeyDown={keydownHandler}
                                onChange={(e) => setPhone(e.target.value)}
                                type={"number"}
                            />
                            {phoneError && <span className="text-error">Phone number is required !</span>}
                        </label>

                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={submitCreateCourseWithPhone}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </nav>

}

export default Header;