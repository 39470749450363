import React, {useEffect, useState} from "react";
import ChatMessage from "../chat/components/chatMessage";
import userIcon from '../../../images/user-black.png';
import {extractQuestions} from "../../utils/extractSuggestedQuestions";
import Chat from "../chat/components/chat";
import FetchClient from "../../utils/fetchClient";
import {Box, Grid, Tab, Tabs} from "@mui/material";
import TabPanel from "../../components/tab_pannel";
import Select from 'react-select';
import * as yup from 'yup';
import { Option } from "../../components/selectOptions";
const SimpleChat = (props) => {
    const [inputs, setInputs] =
        useState({title: ""});
    const [value, setValue] = useState(0);
    const [errorsList, setErrorsList] = useState([]);
    const [selectedModel, setSelectedModel] = useState("");
    const [newChat, setNewChat] = useState("");

    const wizard = props?.wizard;
    const assistant = props?.assistant;

    const handleChange = (event, newValue) => {
        if (newValue === 1 && selectedModel === "") {
            window.$notification({
                text: 'Please select a model',
                variant: 'error',
                position: 'center-top'
            });
        } else {
            setValue(newValue);
        }
    };
    const keyUpHandler = (event, mode) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitChat();
        }
        return true;
    };
    const submitChat = async () => {
        const schema = yup.object().shape({
            inputs: yup.object().shape({
                title: yup.string().required("Title is required !"),
            }),
        });
        try {
            await schema.validate({inputs}, {abortEarly: false});
            FetchClient({
                method: 'post',
                url: `chats`,
                data: {
                    title: inputs.title,
                    type: "general",
                    model: 'gpt-3.5-turbo',
                    user_id: props.userProfile.id,
                    course_id: props.course.id,
                    assistant_id: wizard.assistant_id
                },
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // },
            }).then(response => {
                setInputs({title: ""});
                setNewChat(response?.data);
                setValue(1);
                setErrorsList([]);

            });
        } catch (err) {
            setErrorsList(err.inner);
        }

    };
    return (
        <Box className="w-full rounded-lg dark:bg-navy-800 justify-start">
            <div class="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div class="flex items-center space-x-2">
                    <div
                        class="flex h-7 w-7 items-center justify-center rounded-full bg-primary/10 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                        <span>{value + 1}</span>
                    </div>
                    <h4 class="text-lg font-medium text-slate-700 dark:text-navy-100">
                        {wizard.name}
                    </h4>
                </div>
            </div>
            <div className="flex flex-col divide-y divide-slate-150 px-4 dark:divide-navy-500 sm:px-5">
                <div className="mt-8">
                    <ol className="steps line-space [--size:.75rem] [--line:1px]">
                        <li className="step before:bg-slate-200 dark:before:bg-navy-500">
                            <div className="step-header rounded-full bg-primary dark:bg-accent">
                                    <span
                                        className={(value === 0 ? "" : "hidden ") + "inline-flex h-full w-full animate-ping rounded-full bg-primary opacity-80 dark:bg-primary-light"}></span>
                            </div>
                            <h3 className={(value === 0 ? "font-bold " : "cursor-pointer ") + "text-xs+ text-slate-600 dark:text-navy-100"}
                                onClick={() => setValue(0)}>
                                Step 1: Chat Info</h3>
                        </li>
                        <li className="step before:bg-slate-200 dark:before:bg-navy-500">
                            <div className="step-header rounded-full bg-secondary dark:bg-secondary-light firefox:!-mt-1.5">
                                    <span
                                        className={(value === 1 ? "" : "hidden ") + "inline-flex h-full w-full animate-ping rounded-full bg-secondary opacity-80 dark:bg-secondary-light"}></span>
                            </div>
                            <h3 className={(value === 1 ? "font-bold " : "cursor-pointer ") + "text-xs+ text-slate-600 dark:text-navy-100"}
                                onClick={() => setValue(1)}>
                                Step 2: Chat</h3>
                        </li>
                    </ol>
                </div>
                <div class="my-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                <Tabs
                    className="invisible !min-h-0 !h-0"
                    variant="scrollable"
                    scrollButtons={true}
                    onChange={handleChange}
                    value={value}
                    aria-label="scrollable prevent tabs example">
                    <Tab disabled={value > 0} label="Create Chat"/>
                    <Tab disabled={value > 1 || value === 0} label="Chat"/>
                </Tabs>
                <TabPanel value={value} index={0} className="border-none pb-5">
                    <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5"
                          onKeyUp={(event) => keyUpHandler(event, "create")}>
                        <ChatMessage asssitant_image_url={assistant?.image_file_url}
                                     lastItem={false} role="assistant"
                                     loading={false}
                                     key={2}
                                     content={"Please specify your chat's title and type."}
                                     id={2}
                        />
                        <div className="mt-4 space-y-4">
                            {/*<label*/}
                            {/*    className={"block" + (props.course?.assistant_selection === 'random' ? ' hidden' : '')}>*/}
                            {/*    <span>Assistant:</span>*/}
                            {/*    <Select*/}
                            {/*        styles={{*/}
                            {/*            menuPortal: (base) => ({...base, zIndex: 9999})*/}
                            {/*        }}*/}
                            {/*        loadingMessage={() => 'Loading ...'}*/}
                            {/*        closeMenuOnSelect={true}*/}
                            {/*        value={selectedAssistant}*/}
                            {/*        isMulti={false}*/}
                            {/*        components={{Option}}*/}
                            {/*        menuPortalTarget={document.body}*/}
                            {/*        options={props.assistants}*/}
                            {/*        getOptionLabel={(option) => option?.name}*/}
                            {/*        getOptionValue={(option) => option?.id}*/}
                            {/*        onChange={selectAssistant}*/}
                            {/*    />*/}
                            {/*    <span*/}
                            {/*        className="text-error-focus">{errorsList.filter((name) => name.path === "selectedAssistant.id")?.[0]?.errors?.[0]}</span>*/}
                            {/*</label>*/}
                            <label className="block">
                                <span>Title:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Your Chat Title"
                                    name="title"
                                    value={inputs.title}
                                    onChange={(e) => setInputs(values => ({...values, title: e.target.value}))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "inputs.title")?.[0]?.errors?.[0]}</span>
                            </label>
                        </div>
                    </form>
                    <div className="w-full flex justify-end">
                        <button
                            className="btn w-24 border font-medium text-white dark:text-slate-100 dark:border-navy-500 bg-jclogo-300 dark:bg-jclogo-200 hover:bg-jclogo-200 dark:hover:bg-jclogo-100"
                            onClick={(event) => submitChat()}>
                            <span>Next</span>
                            <svg width="15px" height="15px" viewBox="0 0 1024 1024" class="icon" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                                          fill="#ffffff"></path>
                                </g>
                            </svg>
                        </button>
                    </div>

                </TabPanel>
                <TabPanel value={value} index={1} className="border-none">
                        <Chat  screenHeight={"h-[calc(100vh-18rem)]"} topMargin={"mt-0"} sidePadding={"!-mx-8"} isGeneral general={newChat} />
                </TabPanel>
            </div>
        </Box>
    )
}

export default SimpleChat;