import FetchClient from "../../../utils/fetchClient";
import React, {useEffect, useState, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../../utils/authProvider";
import ReactQuill from "react-quill";
import {Dialog} from "@mui/material";
import * as yup from 'yup';
import MessageChat from "./message";
import {openCloseSidebarPanel} from "../../../utils/helpers";

const HistoryMessage = () => {
    const location = useLocation();
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [selecetedMessage, setSelectedMessage] = useState({});
    const [errorsList, setErrorsList] = useState([]);
    const [historyMessage, setHistoryMessage] = useState([]);
    const store = useAuth();
    const courseId = store?.stores?.course?.id;
    const user = store?.stores?.user;
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [feedbackInputs, setFeedbackInputs] =
        useState({content: ""});
    const navigate = useNavigate();

    const handleFeedbackHTMLChange = (content, delta, source, editor) => {
        setFeedbackInputs({content: content});
    }

    const messageId = location?.state?.messageId;

    const setIsRead = (data) => {
        FetchClient({
            method: 'post',
            url: 'course/course_email/read',
            data: {
                course_id: data[0]?.course_id,
                receiver_id: user?.id,
                course_email_ids: data.map(item => item.id),
                is_read: true
            }
        }).then(response => {
        })

    }
    const getHistoryMessages = () => {

        FetchClient({
            method: 'get',
            url: 'course/course_email/history',
            params: {
                course_id: courseId,
                course_email_id: window.location.pathname.split("/")[3]
            }
        }).then(response => {
            setHistoryMessage(response?.data?.items);
            setSelectedMessage(window.location.pathname.split("/")[3]);
            setIsRead(response?.data?.items);
        })
    }
    useEffect(() => {
        getHistoryMessages();
        openCloseSidebarPanel(false);
    }, [])
    const postReply = async (event) => {
        event.preventDefault();
        const schema = yup.object().shape({
            feedbackInputs: yup.object().shape({
                content: yup.string().required("Reply message is required !"),
            }),
        });
        try {
            await schema.validate({feedbackInputs, selectedUserList}, {abortEarly: false});
            FetchClient({
                method: 'post',
                url: 'course/course_email/reply',
                data: {
                    course_id: courseId,
                    sender_id: user?.id,
                    sender_name: user?.name,
                    body: feedbackInputs?.content,
                    subject: historyMessage[0]?.subject,
                    receivers_info: [{
                        receiver_id: historyMessage[historyMessage?.length - 1]?.sender_id,
                        receiver_name: historyMessage[historyMessage?.length - 1]?.sender_name
                    }],
                    parent_id: selecetedMessage,
                    first_parent_id: selecetedMessage
                }

            }).then(response => {
                setFeedbackModal(false);
                getHistoryMessages();
                window.$notification({
                    text: 'Your message sent successfully !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((er) => {
            })
        } catch (err) {
            setErrorsList(err.inner);
        }
    }
    return (
        <main className={`main-content mt-0 flex flex-col h-100vh`}>
            <div
                className="scrollbar-sm grow overflow-y-auto px-[calc(var(--margin-x)-.5rem)] py-2 transition-all duration-[.25s]"
            >
                <div
                    className="scrollbar-sm grow mt-20 bg-white p-4 flex flex-col items-start overflow-y-auto transition-all duration-[.25s] dark:bg-navy-700 dark:text-slate-700">
                    <div className="flex w-full justify-between mb-8">
                        <h1 className="text-xl font-medium text-slate-900 dark:text-navy-50 lg:text-2xl ml-4">
                            {historyMessage[0]?.subject}
                        </h1>
                        <button
                            onClick={() => navigate("/messages")}
                            className={"btn flex justify-between h-4 w-34 p-5 whitespace-nowrap bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 "}>
                            <svg width="20px" height="25px" fill="#ffffff" viewBox="0 0 24 24" id="left-arrow"
                                 xmlns="http://www.w3.org/2000/svg" class="icon line">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path id="primary" d="M21,12H3M6,9,3,12l3,3"
                                          stroke="#ffffff"></path>
                                </g>
                            </svg>
                            <span>
                            Back
                        </span>
                        </button>
                    </div>
                    {historyMessage.map((m, index) => (
                        <MessageChat key={index} message={m} user={user}/>
                    ))}
                    {/* <div ref={messagesEndRef}/> */}
                    {historyMessage[historyMessage?.length - 1]?.sender_id === user.id ? "" :
                        <div className="flex w-full justify-end mt-3">
                            <button
                                onClick={() => setFeedbackModal(true)}
                                className={"btn flex justify-between h-4 p-5 whitespace-nowrap bg-emerald-400 font-medium text-white dark:text-slate-200 dark:bg-emerald-400"}>
                                <span>Reply</span>
                            </button>
                        </div>}
                </div>

                <Dialog open={feedbackModal}
                        onClose={() => setFeedbackModal(false)}
                        aria-labelledby="alert-dialog-title"
                        sx={{"& .MuiPaper-root": {width: "55%"}}}
                        aria-describedby="alert-dialog-description">
                    <div
                        className="modal-content relative flex w-full flex-col overflow-hidden bg-white dark:bg-navy-700"
                    >
                        <div
                            className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                        >
                            <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                                Reply
                            </h3>
                            <button
                                onClick={() => {
                                    setFeedbackModal(false);
                                    setFeedbackInputs({content: ""})
                                }}
                                className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4.5 w-4.5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                            <div className="mt-4 space-y-4">
                                <label className="block">
                                    <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                                value={feedbackInputs.content}
                                                onChange={handleFeedbackHTMLChange}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "replyMessage.content")?.[0]?.errors?.[0]}</span>
                                </label>
                                <div className="space-x-2 text-right">
                                    <button
                                        className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                        onClick={() => {
                                            setFeedbackModal(false);
                                            setFeedbackInputs({content: ""})
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={(e) => postReply(e)}
                                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </div>
        </main>
    )
}

export default HistoryMessage;